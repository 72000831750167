import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { UseCreateAccessRequest } from "../../../hooks/InviteSystem";

const RequestAccessForm = ({ open, setOpen }) => {
  const resetForm = (targets) => {
    targets.name.value = "";
    targets.email.value = "";
  };
  const CreateNewAccessMutation = UseCreateAccessRequest();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Request Access</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The platform is invite only.To recieve an invitation link, Kindly
          request access by adding your Name and Email and the team will get
          back to you in less than 30 mins.
        </DialogContentText>

        {CreateNewAccessMutation.isError && (
          <Alert variant="filled" severity="error">
            {CreateNewAccessMutation.data.message}
          </Alert>
        )}

        {CreateNewAccessMutation.isSuccess && (
          <Alert variant="filled" severity="success">
            {CreateNewAccessMutation.data.message}
          </Alert>
        )}
        <Box
          component="form"
          validate
          autoComplete="off"
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const data = {
              access: formJson,
            };
            CreateNewAccessMutation.mutate(data);
            resetForm(event.currentTarget);
          }}
        >
          &nbsp; &nbsp;
          <TextField
            required
            margin="dense"
            id="name"
            name="name"
            label="Name"
            fullWidth
            variant="outlined"
            helperText="Please enter your name"
          />
          <TextField
            required
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            helperText="Please enter your email"
          />
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="error"
              disabled={CreateNewAccessMutation.isLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={CreateNewAccessMutation.isLoading}
            >
              {CreateNewAccessMutation.isLoading
                ? "Sending request...."
                : "Submit"}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
      &nbsp;
    </Dialog>
  );
};

export default RequestAccessForm;
