import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import styles from "./css/main.module.css";
import InvitationForm from "./InvitationForm";
import CircularProgress from "@mui/material/CircularProgress";
import { NotificationContainer } from "react-notifications";
import { NotificationManager } from "react-notifications";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Alert from "@mui/material/Alert";

import InvitesTable from "./InvitesTable";
import {
  destroyAnInvite,
  getAllInvites,
  sendAnInvite,
} from "../../services/InviteSystem/inviteSystemService";
import { Button } from "@mui/material";
import { UseFetchAllAccessRequests } from "../../hooks/InviteSystem";
import AccessTable from "./AccessTable";

function Invitation() {
  const [invitationState, setInvitationState] = useState([]);
  const [loader, setLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [linkTab, setLinkTab] = useState(false);

  const {
    data: accessRequests,
    isLoading,
    isError,
  } = UseFetchAllAccessRequests();

  //   === FETCH ALL INVITES

  const fetchAllInvitations = async () => {
    setLoader(true);
    try {
      const { result } = await getAllInvites();
      setInvitationState([...result]);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchAllInvitations();
  }, [linkTab]);

  //   create a new invite
  const handleCreateInvitation = async () => {
    setLoader(true);
    const data = {
      invitation: {
        email: email,
      },
    };

    try {
      const { message } = await sendAnInvite(data);
      if (message === "User already exists in the platform") {
        NotificationManager.error(`${message}`);
        setLoader(false);
        setEmail("");
        return;
      }
      if (message === "An invitation has already been sent to this user") {
        NotificationManager.error(`${message}`);
        setLoader(false);
        setEmail("");
        return;
      }
      NotificationManager.success(`${message}`);
      setLoader(false);
      setEmail("");
      fetchAllInvitations();
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  //   delete an invite

  const handleDeleteInvite = async (inviteId) => {
    setDeleteLoader(true);
    try {
      const { message } = await destroyAnInvite(inviteId);
      if (message === "Invitation deleted") {
        NotificationManager.success(`${message}`);
        setDeleteLoader(false);
        fetchAllInvitations();
      }
      setDeleteLoader(false);
    } catch (error) {
      console.log(error);
      setDeleteLoader(false);
    }
  };

  const displayTabs = () => {
    if (linkTab === true) {
      return (
        <>
          <div>
            <h3 className={styles.header_text}>
              Recieved platform Access requests.
            </h3>
            <h6 className={styles.header_small_text}>
              This are the users who have requested to join the platform. You
              can either approve or reject. If approved an invitation link will
              be sent to them. If rejected an apology email will be sent to
              them.
            </h6>
          </div>

          <hr />
          <div className={`container-fluid ${styles.invite_table_container}`}>
            {isLoading ? (
              <CircularProgress color="secondary" />
            ) : isError ? (
              <Alert variant="filled" severity="success">
                Something went wrong... Kindly refresh
              </Alert>
            ) : (
              <AccessTable accessRequests={accessRequests} />
            )}
          </div>
        </>
      );
    } else {
      return (
        <div>
          <h3 className={styles.header_text}>
            Invite a new user to TUC platform.
          </h3>
          <h6 className={styles.header_small_text}>
            Send out an invite by adding an email of the user you want to
            invite.
          </h6>
          <div className={styles.form_container}>
            <InvitationForm
              setEmail={setEmail}
              email={email}
              handleCreateInvitation={handleCreateInvitation}
              loader={loader}
            />
          </div>
          &nbsp; &nbsp; &nbsp;
          <div className={`container-fluid ${styles.invite_table_container}`}>
            {loader ? (
              <CircularProgress color="secondary" />
            ) : (
              <InvitesTable
                invitationState={invitationState}
                handleDeleteInvite={handleDeleteInvite}
                loader={deleteLoader}
              />
            )}
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <Navbar />
      <NotificationContainer />
      <div className={styles.main_container}>
        <div className={`container-fluid ${styles.invitation_container}`}>
          <nav
            className="navbar navbar-light  bg-light mb-4 text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant={linkTab ? "outlined" : "contained"}
              color={linkTab ? "primary" : "secondary"}
              startIcon={<AddReactionIcon />}
              onClick={() => setLinkTab(false)}
            >
              Invite A New User
            </Button>
            &nbsp; &nbsp; &nbsp;
            <Button
              variant={linkTab ? "contained" : "outlined"}
              color={linkTab ? "secondary" : "primary"}
              startIcon={<CheckBoxIcon />}
              onClick={() => setLinkTab(true)}
            >
              Invitation Link Requests
            </Button>
          </nav>
          &nbsp; &nbsp; &nbsp;
          {displayTabs()}
        </div>
      </div>
    </>
  );
}

export default Invitation;
