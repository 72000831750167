import React, { useState } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { TextareaAutosize } from "@mui/base";
import Alert from "@mui/material/Alert";
import DialogActions from "@mui/material/DialogActions";

import Box from "@mui/material/Box";
import { UseCreateLandingPageContact } from "../../hooks/LandingPage";
const Contact = () => {
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const createContactFormMutation = UseCreateLandingPageContact(() => {
    setContactForm({
      name: "",
      email: "",
      message: "",
    });
  });

  const handleFormSubmit = () => {
    const data = {
      contact: {
        ...contactForm,
      },
    };
    createContactFormMutation.mutate(data);
  };

  return (
    <div className="contact-content">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <h3 className="mb-3 mt-5 font-weight-bold">Contact Us</h3>
            <h6 className="mb-4">
              JOIN US in building THE ULTIMATE COLLABORATION (TUC), the world’s
              largest experiment, and help all Good Ideas reach their full
              potential.
            </h6>
            <h6 className="subtitle font-weight-bold ">
              Efficiency and Happiness Guaranteed!
            </h6>
          </div>
        </div>
      </div>
      {/* --- FORM --- */}
      <Box
        style={{
          border: "1px solid #000",
          padding: "20px",
          borderRadius: "5px",
        }}
        component="form"
        validate
        autoComplete="off"
        onSubmit={(event) => {
          event.preventDefault();
          handleFormSubmit();
        }}
        className="box_form_shadow"
      >
        {createContactFormMutation.isError && (
          <Alert
            variant="filled"
            severity="error"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Something went wrong while establishing a contact...
          </Alert>
        )}
        {createContactFormMutation.isSuccess && (
          <Alert
            variant="filled"
            severity="success"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Message sent succesfully
          </Alert>
        )}
        &nbsp; &nbsp;
        <TextField
          value={contactForm.name}
          required
          margin="dense"
          id="name"
          name="name"
          label="Name"
          fullWidth
          variant="outlined"
          helperText="Please enter your name"
          style={{
            padding: "5px",
          }}
          onChange={(e) =>
            setContactForm({
              ...contactForm,
              name: e.target.value,
            })
          }
        />
        <TextField
          value={contactForm.email}
          required
          margin="dense"
          id="email"
          name="email"
          label="Email Address"
          type="email"
          fullWidth
          variant="outlined"
          helperText="Please enter your email"
          style={{
            padding: "5px",
          }}
          onChange={(e) =>
            setContactForm({
              ...contactForm,
              email: e.target.value,
            })
          }
        />
        <TextareaAutosize
          value={contactForm.message}
          required
          id="message"
          name="message"
          label="Enter your message"
          rowsMin={5}
          cols={101}
          placeholder="Type your message here..."
          type="text"
          style={{
            marginLeft: "10px",
            border: "1.5px solid #ccc",
            borderRadius: "5px",
          }}
          helperText="Please enter your message here"
          onChange={(e) => {
            setContactForm({
              ...contactForm,
              message: e.target.value,
            });
          }}
        />
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={createContactFormMutation.isLoading}
          >
            {createContactFormMutation.isLoading
              ? "Sending message..."
              : "Submit."}
          </Button>
        </DialogActions>
      </Box>
    </div>
  );
};

export default Contact;
