import React from "react";
import styles from "./css/main.module.css";
import TopBar from "./reusableComponents/TopBar";
import DesktopSideBar from "./reusableComponents/DesktopSideBar";
import InfoCard from "./reusableComponents/InfoCard";
import MostEngagedCard from "./reusableComponents/MostEngagedCard";
import MostRecentActivity from "./reusableComponents/MostRecentActivity";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MostEngagedSuggestionCard from "./reusableComponents/MostEngagedSuggestionCard";
import AssistantIcon from "@mui/icons-material/Assistant";
import LatestProject from "./reusableComponents/LatestProject";
import ReleaseNotesCardDisplay from "./reusableComponents/ReleaseNotesCardDisplay";

const DashBoardDesktop = ({ props }) => {
  const { profile, features, dashboardContent, handleUnfollowAction } = props;

  return (
    <div className={styles.desk_top_view}>
      <div className={styles.desk_top_side_bar}>
        <DesktopSideBar features={features} profile={profile} />
      </div>
      <div className={styles.desk_top_info_container}>
        {/* <TopBar profile={profile} /> */}
        <div className={`container-fluid ${styles.main_area_info_container}`}>
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="row">
                <div className="col-xl-6">
                  <InfoCard dashboardContent={dashboardContent} />
                </div>
                <div className="col-xl-6">
                  <MostEngagedCard
                    title={"Most Engaged Project"}
                    icon={
                      <AccountTreeIcon
                        sx={{
                          fontSize: "32px",
                          marginRight: "5px",
                          fontWeight: "600",
                        }}
                      />
                    }
                    project_activity={dashboardContent.project_activity}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <MostEngagedSuggestionCard
                    title={"Most Engaged Suggestion"}
                    suggestion_activity={dashboardContent.suggestion_activity}
                    icon={
                      <AssistantIcon
                        sx={{
                          fontSize: "32px",
                          marginRight: "5px",
                          fontWeight: "600",
                        }}
                      />
                    }
                  />
                </div>
                <div className="col-xl-6">
                  <LatestProject
                    title={"New Project"}
                    icon={
                      <AccountTreeIcon
                        sx={{
                          fontSize: "32px",
                          marginRight: "5px",
                          fontWeight: "600",
                        }}
                      />
                    }
                    project_activity={dashboardContent.recent_created_project}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <ReleaseNotesCardDisplay
                    release_activity={dashboardContent.recent_release_notes}
                    handleUnfollowAction={handleUnfollowAction}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-12 col-md-12">
              <MostRecentActivity dashboardContent={dashboardContent} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoardDesktop;
