import React from "react";
import Logo from "../../assets/img/logo.png";
const Footer = () => {
  return (
    <div className="footer-content">
      <div className="footer-group pb-5 d-block d-lg-flex">
        <div className="footer-left pb-5">
          <div className="footer-group-one">
            <div className="pb-3"></div>
          </div>
        </div>
        <div className="footer-right d-flex w-100 justify-content-around">
          <h5
            style={{
              textTransform: "uppercase",
            }}
          >
            <img
              src={Logo}
              alt=""
              className="footer-logo"
              style={{
                height: " 80px",
                width: " 80px",
              }}
            />{" "}
            The Ultimate Collaboration
          </h5>
        </div>
      </div>
      <hr className="" />
      <div>
        <div className="d-flex">
          <div className="p-2">
            <p>© 2021 TUC. All rights reserved</p>
          </div>
          <div className="ml-auto">
            <div className="d-flex">
              <p className="p-2">Terms & Conditions | </p>
              <p className="p-2"> Privacy Policy </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
