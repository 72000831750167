import React from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import styles from "../css/main.module.css";
import ReadMoreReusable from "../../reusablecomponents/ReadMoreReusable";

const LatestProject = ({ title, icon, project_activity }) => {
  const history = useHistory();

  const navigateToProject = (id) => {
    history.push({
      pathname: `/projects/${id}`,
    });
  };

  const displayProjectInfo = () => {
    if (_.isEmpty(project_activity.project)) return null;

    return (
      <div
        onClick={() => navigateToProject(project_activity.project.id)}
        className={styles.improved_project_name}
        style={{
          cursor: "pointer",
          marginBottom: "15px",
          textTransform: "uppercase",
          fontSize: "19px",
        }}
      >
        <div className={styles.project_name_body}>
          <ReadMoreReusable>
            {`${project_activity.project.name}`}
          </ReadMoreReusable>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.info_card_container}>
      <div className={styles.info_card_header}>
        {" "}
        {icon}
        <h4 className={styles.info_card_header_title}>{title}</h4>
      </div>
      <div className={styles.info_card_body}>
        <div className={styles.card_info_header}>{displayProjectInfo()}</div>
        <div className={styles.card_info_body}>
          <div
            className={styles.number_value_container}
            style={{
              textAlign: "center",
            }}
          >
            <h2 className={styles.number_value}>{project_activity.count}</h2>
            <h5 className={styles.name_value}>Engagements</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestProject;
