import { useMutation, useQuery } from "react-query";
import {
  createNewLandingPageContact,
  getAllLandingPageMesages,
} from "../../services/landingPage";

const UseGetAllLandingPageMessages = () => {
  const fetchMessages = async () => {
    const { result } = await getAllLandingPageMesages();
    return result;
  };
  return useQuery({
    queryKey: ["landing_page_messages"],
    queryFn: fetchMessages,
  });
};

const UseCreateLandingPageContact = (fn) => {
  return useMutation({
    mutationFn: async (data) => createNewLandingPageContact(data),
    onSuccess: async (backendResponse, variable) => {
      fn();
    },
  });
};

export { UseCreateLandingPageContact, UseGetAllLandingPageMessages };
