/*eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import LandingBtn from "./button";
import { useHistory } from "react-router-dom";

const Header = () => {
  let history = useHistory();

  const navigateTosigin = () => {
    history.push("/signin");
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-6 welcome-msg">
        <div className="welcome-msg-content">
          <h1 className="">
            Welcome To <br /> The Ultimate Collaboration
          </h1>
          <p className="">
            TUC is more than a platform—it’s a regenerative system that fosters
            collaboration, transforms challenges into opportunities, and evolves
            with its users. Anchored by the world’s most connected Suggestion
            Box, TUC ensures every idea finds its place, every voice is heard,
            and every contribution creates lasting impact. We’re building the
            world’s greatest experiment—a global safety net where everyone is
            included, and every idea can reach its full potential. With features
            designed for coordinated collaboration and continuous improvement,
            TUC is shaping a future where good ideas thrive, problems spark
            solutions, and opportunities are accessible to all. Together, we’re
            creating a system with lasting impact, designed to achieve the
            SDG2030 agenda. Join us in shaping a transparent, equitable, and
            transformative future. #TogetherWeCan
          </p>
          <p className="together-bold">
            Together we will create the future we need to see
          </p>
          {/* <div className="centre-button-landing float-left d-none d-lg-block">
            <LandingBtn name="Join Us" clicked={navigateTosigin} />
          </div> */}
        </div>
      </div>

      <div className="col-12 col-lg-6 welcome-video">
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            src="https://player.vimeo.com/video/664182433?h=a1b04ff564"
            width="640"
            height="564"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
        <div className="d-lg-none centre-button-landing">
          <button className="btn landing-cta-btn-sm">CTA BUTTON</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
