import React from "react";
import { useHistory } from "react-router-dom";
import styles from "../css/main.module.css";
import _ from "lodash";
import ReadMoreReusable from "../../reusablecomponents/ReadMoreReusable";

const MostEngagedSuggestionCard = ({ suggestion_activity, icon, title }) => {
  const history = useHistory();

  const navigateToSuggestion = (id) => {
    history.push({
      pathname: `/suggestion/${id}`,
    });
  };

  const displayProjectInfo = () => {
    if (_.isEmpty(suggestion_activity.suggestion)) return null;

    const value = suggestion_activity.suggestion.idea;

    return (
      <div
        onClick={() => navigateToSuggestion(suggestion_activity.suggestion.id)}
        className={styles.improved_project_name}
        style={{
          cursor: "pointer",
          marginBottom: "15px",
          textTransform: "uppercase",
          fontSize: "19px",
        }}
      >
        <div className={styles.project_name_body}>
          <ReadMoreReusable>{`${value}`}</ReadMoreReusable>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.info_card_container}>
      <div className={styles.info_card_header}>
        {" "}
        {icon}
        <h4 className={styles.info_card_header_title}>{title}</h4>
      </div>
      <div className={styles.info_card_body}>
        <div className={styles.card_info_header}>{displayProjectInfo()}</div>
        <div className={styles.card_info_body}>
          <div
            className={styles.number_value_container}
            style={{
              textAlign: "center",
            }}
          >
            <h2 className={styles.number_value}>{suggestion_activity.count}</h2>
            <h5 className={styles.name_value}>Engagements</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostEngagedSuggestionCard;
