import React, { useState } from "react";
import Navbar from "../components/navbar/Navbar";
import SideBarNavigation from "../components/ProjectManager/SideBarNavigation";
import AdminAppBar from "../components/ProjectManager/AdminAppBar";
import AssumptionSettings from "../components/ProjectManager/Components/AssumptionSettings";
import EditAssumptionCategory from "../components/ProjectManager/Components/EditAssumptionCategory";
import EditAssumptionsSubCategory from "../components/ProjectManager/Components/EditAssumptionsSubCategory";

const ProjectManagerContainer = () => {
  const [open, setOpen] = useState(false);
  const [categoryEdit, setCategoryEdit] = useState("");
  const [categoryID, setCategoryID] = useState(null);
  const [tab, setTab] = useState("ass");
  const [subCategoryEdit, setSubCategoryEdit] = useState(false);
  const [subCategoryID, setSubCategoryID] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [assumption_category_id, set_assumption_category_id] = useState(null);
  return (
    <>
      {" "}
      <Navbar />
      <EditAssumptionCategory
        setOpen={setOpen}
        open={open}
        categoryEdit={categoryEdit}
        setCategoryEdit={setCategoryEdit}
        categoryID={categoryID}
      />
      <EditAssumptionsSubCategory
        open={subCategoryEdit}
        setOpen={setSubCategoryEdit}
        subCategoryID={subCategoryID}
        subCategoryName={subCategoryName}
        setSubCategoryName={setSubCategoryName}
        assumption_category_id={assumption_category_id}
      />
      <div className="main-body-area-single-project">
        <SideBarNavigation tab={tab} setTab={setTab} />

        <div className="project-static-area-space ">
          <AdminAppBar />
          <div className="sections_dash_areas">
            <AssumptionSettings
              openCategoryEditModal={setOpen}
              setCategoryEdit={setCategoryEdit}
              setEditCategoryID={setCategoryID}
              setSubCategoryEdit={setSubCategoryEdit}
              setSubCategoryID={setSubCategoryID}
              setSubCategoryName={setSubCategoryName}
              set_assumption_category_id={set_assumption_category_id}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectManagerContainer;
