import React, { useState } from "react";
import Slider from "react-slick";
import commentsData from "./commentsData";

const Comments = () => {
  const [commentIndex, setCommentIndex] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    lazyLoad: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (current, next) => setCommentIndex(next),
  };

  return (
    <div className="comments-content">
      <div className="comment-header">
        <h3
          className="text-center comment-title"
          style={{
            textTransform: "uppercase",
            color: "black",
            fontWeight: "800",
            textDecoration: "underline",
            marginTop: "15px",
          }}
        >
          See What Our Team has to Say
        </h3>
      </div>
      <div className="text-center">
        <Slider {...settings}>
          {commentsData.map((data, idx) => (
            <div
              key={`cc${idx}`}
              className={idx === commentIndex ? "slide activeSlide" : "slide"}
            >
              <div>
                <div className="card comment-card mb-5">
                  <div className="card-body">
                    <h5 className="card-title">{data.title}</h5>
                    <p className="card-text">
                      <sup>
                        <i className="fas fa-quote-left fa-2x text-primary"></i>{" "}
                      </sup>
                      {data.body}
                    </p>
                  </div>
                </div>
                <div>
                  <img
                    src={data.pics}
                    className="img-fluid comment-img mb-3"
                    alt=""
                  />
                  <span>
                    <i className="fas fa-star text-warning"></i>
                  </span>
                  <span>
                    <i className="fas fa-star text-warning"></i>
                  </span>
                  <span>
                    <i className="fas fa-star text-warning"></i>
                  </span>
                  <span>
                    <i className="fas fa-star text-warning"></i>
                  </span>
                  <span>
                    <i className="fas fa-star text-warning"></i>
                  </span>
                  <p>{data.profession}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Comments;
