import React from "react";
import LandingBtn from "./button";
import "../../stylesheets/landingPage.css";

const Pricing = () => {
  return (
    <div className="pricing8 py-5">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <h3
              className="mb-3 font-weight-bold"
              style={{
                textTransform: "uppercase",
                color: "black",
                fontWeight: "800",
                textDecoration: "underline",
                // Add some space between the title and the content
              }}
            >
              Future Partnership Model
            </h3>
            <h6 className="subtitle">
              Stop wasting time and money just talking about making the world a
              better place.
            </h6>
            <h6 className="subtitle">
              JOIN US in building The Ultimate Collaboration (TUC)—the world’s
              largest experiment in collaboration—and help all Good Ideas reach
              their Full Potential.
            </h6>
            <h6 className="subtitle font-weight-bold ">
              Efficiency and Happiness Guaranteed!
            </h6>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-4 ml-auto pricing-box align-self-center">
            <div className="card mb-4">
              <div className="card-body p-4 text-center">
                <h5
                  style={{
                    textTransform: "uppercase",
                    color: "black",
                    fontWeight: "800",
                    textDecoration: "underline",
                  }}
                >
                  AlphaBeta Users
                </h5>

                <h6
                  className="subtitle "
                  style={{
                    color: "green",
                  }}
                >
                  Be part of something revolutionary. As an AlphaBeta User,
                  you’re not just joining TUC—you’re helping build it from the
                  ground up. Together, we’ll shape a platform that reflects the
                  future we want: equitable, just, and transparent.
                </h6>
                <div className="mt-4">
                  <ul className="text-left">
                    <li className="mb-3">
                      Join an inclusive online community where every voice
                      matters.
                    </li>
                    <li className="mb-3">
                      Find or build your passions with like-minded people who
                      share your vision
                    </li>
                    <li className="mb-3">
                      Access essential resources to make your efforts more
                      impactful.
                    </li>
                    <li className="mb-3">
                      Leverage regenerative systems to share and scale good
                      ideas globally.
                    </li>
                    <li className="mb-3">
                      Engage in collective action to co-create a better, more
                      sustainable world.
                    </li>
                  </ul>
                </div>
                <h6
                  className="subtitle "
                  style={{
                    color: "green",
                  }}
                >
                  Discover your potential, connect with changemakers, and help
                  shape TUC into the platform of the future. Join today!
                  #TogetherWeCan
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-4 ml-auto pricing-box align-self-center">
            <div className="card mb-4">
              <div className="card-body p-4 text-center">
                <h5
                  style={{
                    textTransform: "uppercase",
                    color: "black",
                    fontWeight: "800",
                    textDecoration: "underline",
                  }}
                >
                  Impact Investor
                </h5>

                <h6
                  className="subtitle "
                  style={{
                    color: "green",
                  }}
                >
                  Your investment can spark change and empower good ideas to
                  reach their Full Potential. As an Impact Investor, you’re not
                  just a sponsor—you’re a catalyst for meaningful innovation and
                  progress.
                </h6>

                <div className="mt-4">
                  <ul className="text-left">
                    <li className="mb-3">
                      Create opportunity and help good ideas reach full
                      potential{" "}
                    </li>
                    <li className="mb-3">
                      Be a part of the @AdvisorsNetwork, where your investment
                      fuels mentorship and collaboration.
                    </li>
                    <li className="mb-3">
                      Champion the ideas that matter most by investing in the
                      future through TUC.
                    </li>
                  </ul>
                </div>
                <h6
                  className="subtitle "
                  style={{
                    color: "green",
                  }}
                >
                  Become an Impact Investor today and make a real difference.
                  Together, we can turn potential into progress. #TogetherWeCan
                </h6>
              </div>
            </div>
          </div>

          <div className="col-lg-4 ml-auto pricing-box align-self-center">
            <div className="card mb-4">
              <div className="card-body p-4 text-center">
                <h5
                  style={{
                    textTransform: "uppercase",
                    color: "black",
                    fontWeight: "800",
                    textDecoration: "underline",
                  }}
                >
                  Strategic Partner
                </h5>

                <h6
                  className="subtitle "
                  style={{
                    color: "green",
                  }}
                >
                  Your mission has the power to change the world, and partnering
                  with TUC will help you make an even greater impact. Together,
                  we’ll bridge gaps, aggregate efforts, and amplify your reach
                  to improve the efficacy of every initiative related to your
                  cause.
                </h6>

                <div className="mt-4">
                  <ul className="text-left">
                    <li className="mb-3">
                      Forge a Win-Win partnership focused on sustainability,
                      inclusion, diversity, innovation, and empowerment.
                    </li>
                    <li className="mb-3">
                      Collaborate on groundbreaking initiatives aligned with
                      your vision to tackle global challenges and create lasting
                      change.
                    </li>
                    <li className="mb-3">
                      Leverage TUC’s platform to connect with global networks,
                      aggregate resources, and increase your impact, ensuring
                      every effort contributes to meaningful outcomes.
                    </li>
                  </ul>
                </div>
                <h6
                  className="subtitle "
                  style={{
                    color: "green",
                  }}
                >
                  Join TUC as a Strategic Partner and make your mission
                  unstoppable. Together, we can achieve more. #TogetherWeCan
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
