import React from "react";

import homeWall from "../../assets/img/prospect.png";
import whoweare from "../../assets/img/who_we_are.png";
import abbss from "../../assets/img/ambassador_network_new_image.png";
import opportunity from "../../assets/img/opportunity_is_a_human_right.png";
import "../../stylesheets/landingPage.css";

function Prospect() {
  return (
    <div className="card border-0">
      <h2
        className="card-title mb-5 font-weight-bold text-center"
        style={{
          textTransform: "uppercase",
          color: "black",
          fontWeight: "800",
          textDecoration: "underline",
          marginTop: "15px",
        }}
      >
        This Is What The Future Looks Like!
      </h2>
      <div className="row no-gutters flex-column-reverse flex-lg-row">
        <div className="col-lg-6">
          <div className="card-body abt-card-text">
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <img
                src={whoweare}
                alt="Who We are"
                style={{
                  height: "90px",
                  marginRight: "20px",
                }}
              />
              <h5
                className="card-title"
                style={{
                  position: "relative",
                  top: "30px",
                  textTransform: "uppercase",
                  fontWeight: "800",
                }}
              >
                Who we are
              </h5>
            </div>

            <p className="card-text">
              We are an inclusive online community, united by a shared vision:
              making the world a better place. Here, your ideas, big or small,
              will find the support, tools, and resources to reach their full
              potential. Join us. Together, we’ll turn dreams into action.
            </p>

            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <img
                src={abbss}
                alt="projects"
                style={{
                  height: "90px",
                  marginRight: "20px",
                }}
              />
              <h5
                className="card-title"
                style={{
                  position: "relative",
                  top: "30px",
                  textTransform: "uppercase",
                  fontWeight: "800",
                }}
              >
                Ambassador Program
              </h5>
            </div>

            <p className="card-text">
              Imagine one Ambassador for every community, connecting local needs
              with global solutions. TUC’s Ambassadors are the heart of our
              mission, driving engagement and opportunity in their communities.
              Could you be the voice for your community? Sign up for our mailing
              list to learn how to become an Ambassador.
            </p>

            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <img
                alt="world connected suggestion box"
                src={opportunity}
                style={{
                  height: "90px",
                  marginRight: "20px",
                }}
              />
              <h5
                className="card-title"
                style={{
                  position: "relative",
                  top: "30px",
                  textTransform: "uppercase",
                  fontWeight: "800",
                }}
              >
                Opportunity network
              </h5>
            </div>
            <p className="card-text">
              At TUC, we believe opportunity is a human right. The Opportunity
              Network is designed to bridge gaps and connect resources with
              those who need them most. Whether it’s funding, mentorship, or
              tools, this network empowers individuals and communities to rise
              and thrive. Ready to unlock your potential? Join the Opportunity
              Network and turn challenges into possibilities.
            </p>
          </div>
        </div>
        {/* --- Image sections --- */}
        <div className="col-lg-6">
          <div className="">
            <img src={homeWall} alt="" className="img-fluid prospect-img" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prospect;
