import React, { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import styles from "../singleProject/globals/sidebar.module.css";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ForumIcon from "@mui/icons-material/Forum";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { Divider } from "@mui/material";

const SideBarNavigation = ({ setTab, tab }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sidebar
      backgroundColor="#fff"
      collapsed={collapsed}
      className={styles.side_bar_container}
    >
      <div className={styles.sidebar_top_bar}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6
            style={{
              textTransform: "uppercase",
              marginTop: "25px",
              fontWeight: "900",
            }}
          >
            Project Settings
          </h6>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EngineeringIcon
            sx={{
              fontSize: "50px",
              marginTop: "10px",
              color: "#333",
              fontWeight: "800",
            }}
          />
        </div>
      </div>
      <Divider light={true} className={styles.side_bar_divider} />
      <Menu>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<TipsAndUpdatesIcon />}
          onClick={() => setTab("ass")}
          active={tab === "ass" ? true : false}
          className={tab === "ass" ? `${styles.active_tab}` : null}
        >
          {" "}
          Assumptions Settings
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<FlagCircleIcon />}
          onClick={() => setTab("goa")}
          active={tab === "goa" ? true : false}
          className={tab === "goa" ? `${styles.active_tab}` : null}
        >
          {" "}
          Goals Settings
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<VolunteerActivismIcon />}
          onClick={() => setTab("nee")}
          active={tab === "nee" ? true : false}
          className={tab === "nee" ? `${styles.active_tab}` : null}
        >
          {" "}
          Inventory Settings
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<FormatListNumberedIcon />}
          onClick={() => setTab("top")}
          active={tab === "top" ? true : false}
          className={tab === "top" ? `${styles.active_tab}` : null}
        >
          {" "}
          Top10 Settings
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<ForumIcon />}
          onClick={() => setTab("dis")}
          active={tab === "dis" ? true : false}
          className={tab === "dis" ? `${styles.active_tab}` : null}
        >
          {" "}
          Discussion Settings
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<IntegrationInstructionsIcon />}
          onClick={() => setTab("sug")}
          active={tab === "sug" ? true : false}
          className={tab === "sug" ? `${styles.active_tab}` : null}
        >
          {" "}
          Suggestion Box Settings
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<AccountTreeIcon />}
          onClick={() => setTab("sub")}
          active={tab === "sub" ? true : false}
          className={tab === "sub" ? `${styles.active_tab}` : null}
        >
          {" "}
          Sub Projects{" "}
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<AssessmentIcon />}
          onClick={() => setTab("swo")}
          active={tab === "swo" ? true : false}
          className={tab === "swo" ? `${styles.active_tab}` : null}
        >
          {" "}
          SWOT Analysis Settings
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<LeaderboardIcon />}
          onClick={() => setTab("bmc")}
          active={tab === "bmc" ? true : false}
          className={tab === "bmc" ? `${styles.active_tab}` : null}
        >
          BMC Settings
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default SideBarNavigation;
