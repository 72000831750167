import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { UseEditProjectAssumption } from "../../../hooks/project/singleProject";
import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function EditAssumption({
  closeEditModal,
  openEditModal,
  assumptionID,
  editState,
  projectID,
  assCategories,
  assCategoriesIsLoading,
  assCategoriesIsErrors,
  projectAssumption,
}) {
  //  ==== FORM CONTROL STATE ====
  const [formState, setFormState] = useState({
    assumption: "",
    idea: "",
    purpose: "",
  });

  const [assumptionCategoryValue, setAssumptionCategoryValue] = useState(null);
  const handleChange = (event) => {
    setAssumptionCategoryValue(event.target.value);
  };
  const [subCategory, setSubCategory] = useState([]);
  const [subCategoryValue, setSubCategoryValue] = useState(null);
  const handleSubCategoryChange = (event) => {
    setSubCategoryValue(event.target.value);
  };

  // ==== POPULATE THE EDIT FORM ====
  useEffect(() => {
    setFormState({
      assumption: editState.assumption,
      idea: editState.idea,
      purpose: editState.purpose,
    });
  }, [editState]);

  // --- RQ EDIT ASSUMPTION MUTATION
  const EditAssumptionMutation = UseEditProjectAssumption(closeEditModal);

  //  ==== SUBMIT THE FORM ====
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      assumptionID,
      projectID,
      assumptionData: {
        ...formState,
      },
    };
    EditAssumptionMutation.mutate(data);
  };

  const displayAssumptionsCategories = () => {
    if (assCategoriesIsLoading) return <div>Loading...</div>;
    if (assCategoriesIsErrors)
      return <div>Error loading assumptions categories</div>;
    if (assCategories.length < 1) return null;

    const categoryItems = () => {
      return assCategories.map((value) => {
        return (
          <MenuItem
            value={value.id}
            onClick={() => setSubCategory([...value.sub_categories])}
          >
            {value.name}
          </MenuItem>
        );
      });
    };

    return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">category</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={assumptionCategoryValue}
          label="categories"
          onChange={(event) => handleChange(event)}
        >
          <MenuItem value={null}>None</MenuItem>
          {categoryItems()}
        </Select>
      </FormControl>
    );
  };

  const displayAssumptionsSubCategories = () => {
    if (subCategory.length < 1) return null;
    const subCategoryItems = () => {
      return subCategory.map((value) => {
        return (
          <MenuItem value={value.id} key={value.id}>
            {value.name}
          </MenuItem>
        );
      });
    };

    return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">sub-category</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={subCategoryValue}
          label="sub-categories"
          onChange={(event) => handleSubCategoryChange(event)}
        >
          {subCategoryItems()}
        </Select>
      </FormControl>
    );
  };

  return (
    <Modal show={openEditModal} onHide={closeEditModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#047C7C" }}
        closeButton
      >
        <Modal.Title>Edit Assumption</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {EditAssumptionMutation.isError && (
          <Alert variant="filled" severity="error">
            Something went wrong while editing the assumption. Kindly try again
          </Alert>
        )}

        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="need">Assumption</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={editState.assumption}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, assumption: data });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="idea">Idea</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={editState.idea}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, idea: data });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="purpose">Purpose</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={editState.purpose}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, purpose: data });
              }}
            />
          </div>
          <div className="form-group">{displayAssumptionsCategories()}</div>
          <div className="form-group">{displayAssumptionsSubCategories()}</div>
          <div className="form-group float-right">
            <Button
              variant="contained"
              type="submit"
              disabled={EditAssumptionMutation.isLoading ? true : false}
              color="secondary"
            >
              Edit Assumption
            </Button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  );
}

export default EditAssumption;
