import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  acceptOrRejectAccess,
  fetchAllAccessRequest,
  sendAccessRequest,
} from "../../services/InviteSystem/inviteSystemService";

// --- THIS FETCHES ALL THE ACCESS REQUESTS ----
const UseFetchAllAccessRequests = () => {
  const fetchAccessRequests = async () => {
    const { result } = await fetchAllAccessRequest();
    return result;
  };
  return useQuery({
    queryKey: ["allAccessRequests"],
    queryFn: fetchAccessRequests,
  });
};

const UseCreateAccessRequest = () => {
  return useMutation({
    mutationFn: async (accessData) => sendAccessRequest(accessData),
  });
};

const UseAcceptOrRejectAccess = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (accessData) => acceptOrRejectAccess(accessData),
    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["allAccessRequests"],
      });
    },
  });
};

export {
  UseFetchAllAccessRequests,
  UseCreateAccessRequest,
  UseAcceptOrRejectAccess,
};
