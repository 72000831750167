import React from "react";
import Social from "../../assets/img/foooter_image.png";
function Subscribe() {
  return (
    <div className="subscribe-content">
      <div className="d-flex subscribe-main">
        <div className="d-none d-lg-block">
          <img src={Social} alt="" className="img-fluid subscribe-img" />
        </div>
        <div
          style={{
            marginTop: "10px",
            marginLeft: "20px",
          }}
        >
          <h4
            style={{
              textTransform: "uppercase",
              fontWeight: "900",
              fontSize: "23px",
            }}
          >
            TUC Metrics at a Glance
          </h4>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                {/* --- card --- */}
                <div
                  className="card"
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <h5 className="card-header">Platform Status:</h5>
                  <div className="card-body">
                    <ul className="list-group">
                      <li className="list-group-item">POC2 Progress: 78%</li>
                      <li className="list-group-item">MVP Progress: 10%</li>
                    </ul>
                  </div>
                </div>
                {/* --- card end --- */}
              </div>
              <div className="col-md-6">
                {/* --- card --- */}
                <div
                  className="card"
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <h5 className="card-header">Active Users:</h5>
                  <div className="card-body">
                    <ul className="list-group">
                      <li className="list-group-item">Daily: 3–5</li>
                      <li className="list-group-item">Monthly: 10–15</li>
                    </ul>
                  </div>
                </div>
                {/* --- card end --- */}
              </div>

              <div className="col-md-6">
                {/* --- card --- */}
                <div
                  className="card"
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <h5 className="card-header">Team & Partners:</h5>
                  <div className="card-body">
                    <ul className="list-group">
                      <li className="list-group-item">Fully Engaged: 2</li>
                      <li className="list-group-item">Special Projects: 5</li>
                      <li className="list-group-item">
                        Advisors: 10 (more to come!)
                      </li>
                      <li className="list-group-item">
                        AlphaBeta Users: 🚩 TBD
                      </li>
                      <li className="list-group-item">Confirmed Partners: 0</li>
                      <li className="list-group-item">
                        Partner Candidates: Hundreds and growing!
                      </li>
                      <li className="list-group-item">Sponsors: 2</li>
                      <li className="list-group-item">Investors: 🚩 1</li>
                    </ul>
                  </div>
                </div>
                {/* --- card end --- */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
