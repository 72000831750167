import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { UseEditProjectAssumptionSubCategory } from "../../../hooks/Admin";

const EditAssumptionsSubCategory = ({
  setOpen,
  open,
  subCategoryID,
  subCategoryName,
  setSubCategoryName,
  assumption_category_id,
}) => {
  const EditSubCategoryMutation = UseEditProjectAssumptionSubCategory(() =>
    setOpen(false)
  );
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"md"}
      fullWidth={true}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          const data = {
            id: subCategoryID,
            assumption_category_id: assumption_category_id,
            assData: {
              assumption_sub_category: {
                name: subCategoryName,
              },
            },
          };
          EditSubCategoryMutation.mutate(data);
        },
      }}
    >
      <DialogTitle>EDIT SUB CATEGORY</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="Sub-category"
          label="sub-category"
          type="text"
          fullWidth
          variant="standard"
          value={subCategoryName}
          onChange={(e) => {
            setSubCategoryName(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="error">
          Cancel
        </Button>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          disabled={EditSubCategoryMutation.isLoading}
        >
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAssumptionsSubCategory;
