import React, { Fragment, useState } from "react";
import styles from "./assumptionsSettings.module.css";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import {
  UseCreateProjectAssumptionCategory,
  UseCreateProjectAssumptionSubCategory,
  UseFetchAllProjectAssumptionsCategory,
} from "../../../hooks/Admin";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const AssumptionSettings = ({
  openCategoryEditModal,
  setCategoryEdit,
  setEditCategoryID,
  setSubCategoryEdit,
  setSubCategoryID,
  setSubCategoryName,
  set_assumption_category_id,
}) => {
  const [inputFields, setInputFields] = useState([]);
  const [category, setCategory] = useState("");
  const [open, setOpen] = useState(false);
  const [categoryID, setCategoryID] = useState(null);
  //   ---- RQ -----
  const {
    isError,
    isLoading,
    data: categories,
  } = UseFetchAllProjectAssumptionsCategory();
  const CreateSubCategoryMutation = UseCreateProjectAssumptionSubCategory();
  const handleCreateSubCategory = (ass_id) => {
    if (inputFields.length < 1) {
      setCategory("");
      return;
    }

    inputFields.forEach((value) => {
      const data = {
        assID: ass_id,
        subCatData: {
          assumption_sub_category: {
            name: value.sub_category,
          },
        },
      };
      CreateSubCategoryMutation.mutate(data);
    });
    setCategory("");
    setInputFields([]);
  };

  const CreateCategoryMutation = UseCreateProjectAssumptionCategory(
    handleCreateSubCategory
  );
  const handleCreateCategories = () => {
    const data = {
      assumption_category: {
        name: category,
      },
    };
    CreateCategoryMutation.mutate(data);
  };

  const handleAddField = () => {
    const values = [...inputFields];
    values.push({ sub_category: "" });
    setInputFields(values);
  };

  const handleChange = (index, event) => {
    const values = [...inputFields];
    values[index].sub_category = event.target.value;
    setInputFields(values);
  };

  const handleRemoveField = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const displayCategories = (open, setOpen) => {
    if (isLoading) return <CircularProgress color="secondary" />;

    if (isError)
      return (
        <Alert variant="filled" severity="error">
          Something happend while fetching the categories
        </Alert>
      );

    const displaySubCategories = (data, category_id) => {
      if (data.length < 1) return null;
      return data.map((category) => {
        return (
          <TableRow key={category.id}>
            <TableCell>
              <h6
                style={{
                  color: "green",
                }}
              >
                {category.name}
              </h6>
            </TableCell>
            <TableCell>
              {" "}
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setSubCategoryEdit(true);
                  setSubCategoryID(category.id);
                  setSubCategoryName(category.name);
                  set_assumption_category_id(category_id);
                }}
              >
                Edit
              </Button>
            </TableCell>
          </TableRow>
        );
      });
    };

    return categories.map((value) => {
      return (
        <>
          <TableRow
            sx={{
              width: "100%",
            }}
          >
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setOpen(!open);
                  setCategoryID(value.id);
                }}
              >
                {open && categoryID === value.id ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
              <h6>{value.name}</h6>
            </TableCell>
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setCategoryEdit(value.name);
                  setEditCategoryID(value.id);
                  openCategoryEditModal(true);
                }}
              >
                Edit
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse
                in={open && categoryID === value.id}
                timeout="auto"
                unmountOnExit
              >
                <Box sx={{ margin: 1 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    Sub-Categories
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displaySubCategories(value.sub_categories, value.id)}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      );
    });
  };

  return (
    <div className={styles.assumptions_container}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <Paper elevation={3}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  backgroundColor: "purple",
                  marginBottom: "20px",
                  marginTop: "20px",
                  color: "white",
                }}
              >
                <h6
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "800",
                  }}
                >
                  Create new category and Sub Category
                </h6>
              </div>
              <div className={styles.categories_form_container}>
                <form>
                  <div className="form-group">
                    <label
                      for="categoryName"
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#333",
                        marginBottom: "10px",
                        textTransform: "uppercase",
                      }}
                    >
                      Category Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="categoryName"
                      aria-describedby="emailHelp"
                      placeholder="Name"
                      onChange={(e) => setCategory(e.target.value)}
                      value={category}
                    />
                    <small id="emailHelp" class="form-text text-muted">
                      Add Name of the project assumption category
                    </small>
                  </div>

                  <div className="form-group mt-2 d-flex justify-content-between">
                    <h6
                      style={{
                        fontWeight: "800",
                        textTransform: "uppercase",
                      }}
                      className="pt-2"
                    >
                      Add Sub-category
                    </h6>
                    <button
                      className="btn btn-success"
                      type="button"
                      onClick={() => handleAddField()}
                    >
                      +
                    </button>
                  </div>
                  <div className="row mx-auto">
                    {inputFields.map((field, idx) => (
                      <Fragment key={idx}>
                        <div className="form-group col-md-11 mt-2">
                          <input
                            type="text"
                            className="form-control"
                            id="field_name"
                            name="sub_category"
                            value={field.sub_category}
                            placeholder="sub-category"
                            onChange={(event) => handleChange(idx, event)}
                            required
                          />
                        </div>
                        <div className="form-group col-md-1 remove-btn">
                          <button
                            className="btn btn-danger mt-2"
                            type="button"
                            onClick={() => handleRemoveField(idx)}
                          >
                            -
                          </button>
                        </div>
                      </Fragment>
                    ))}
                  </div>

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleCreateCategories()}
                    disabled={
                      CreateCategoryMutation.isLoading ||
                      CreateSubCategoryMutation.isLoading
                        ? true
                        : false
                    }
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </Paper>
          </div>

          <div className="col-md-8">
            <Paper elevation={3}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  backgroundColor: "purple",
                  marginBottom: "20px",
                  marginTop: "20px",
                  color: "white",
                }}
              >
                <h6
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "800",
                  }}
                >
                  Project Assumption Categories and subcategories
                </h6>
              </div>
              <TableContainer
                component={Paper}
                sx={{
                  width: "100%",
                  padding: "20px",
                }}
              >
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell> Open/Close </TableCell>
                      <TableCell> Category Name</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{displayCategories(open, setOpen)}</TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssumptionSettings;
