/*eslint-disable */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import ProjectDiscussion from "../components/singleProject/ProjectDiscussion";
import ProjectGallery from "../components/singleProject/ProjectGallery";
import ProjectGoals from "../components/singleProject/ProjectGoals";
import ProjectSummary from "../components/singleProject/ProjectSummary";
import ProjectAssumptions from "../components/singleProject/ProjectAssumptions";
import ProjectNeeds from "../components/singleProject/ProjectNeeds";
import ProjectTop10Main from "../components/singleProject/project-top-ten/ProjectTop10Main";
import ProjectSuggestion from "../components/singleProject/project-suggestion-box/ProjectSuggestion";
import PrivacyWarning from "../components/reusablecomponents/PrivacyWarning";

import "../stylesheets/singleProject.css";
import "../stylesheets/profiles.css";
import "../stylesheets/projectmodal.css";
import "../stylesheets/projects.css";
import "../stylesheets/suggestion.css";
import Alert from "@mui/material/Alert";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  createProjectComments,
  createProjectGoals,
  fetchProjectCategory,
  fetchProjectComments,
  fetchSingleProject,
  updateProject,
  createQuickLink,
  fetchProjectsNeeds,
  createProjectNeeds,
  createProjectDiscussion,
  createProject,
  fetchProjects,
  fetchAllProfile,
} from "../redux";
import _ from "lodash";
import Navbar from "../components/navbar/Navbar";
import EditProjectModal from "../components/project/EditProjectModal";
import ProjectCollabModal from "../components/singleProject/ProjectCollabModal";
import ProjectQuickLinkModal from "../components/singleProject/ProjectQuickLinkModal";
import { getProjectSdgs, showProjectSdgs } from "../services/Sdgs/projectSdgs";
import SubProject from "../components/singleProject/subProjects/SubProject";
import SubProjectModal from "../components/singleProject/subProjects/SubProjectModal";
import ProjectSingleForm from "../components/singleProject/ProjectSingleForm";
import SwotContainer from "./SwotContainer";
import BmcContainer from "./BmcContainer";
import {
  getAllSwot,
  createNewSwot,
} from "../services/swotAnalysis/projectSwot";
import { getAllBmc, createNewBmc } from "../services/bmc/projectBmc";
import EditSdgs from "../components/singleProject/sdg/EditSdgs";
import AppRightDrawer from "../components/singleProject/MobileDesign/AppRightDrawer";
import SideBar from "../components/singleProject/globals/SideBar";
import TopProjectBar from "../components/singleProject/globals/TopProjectBar";
import useSpeedDialButtons from "../hooks/project/useSpeedDialButtons";
import ProjectGoalsModal from "../components/singleProject/ProjectGoalsModal";
import CreateAssumption from "../components/singleProject/projectAssumptions/CreateAssumption";
import NeedModal from "../components/masterNeeds/NeedModal";
import ProjectTopTenModal from "../components/singleProject/project-top-ten/ProjectTopTenModal";
import CreateDiscussion from "../components/singleProject/projectDiscussion/CreateDiscussion";
import ProjectCalender from "../components/singleProject/calender/ProjectCalender";
import { getAllProjectOpenItems } from "../services/openItem/projectOpenItemService";
import ProjectQuickLinks from "../components/singleProject/ProjectQuickLinks";
import AppMobileBottomNavigation from "../components/singleProject/globals/AppMobileBottomNavigation";
import AppSideBarProject from "../components/singleProject/globals/AppSideBarProject";
import ProjectEditOfQuickLinkModal from "../components/singleProject/ProjectEditOfQuickLinkModal";
import AddImagesModal from "../components/singleProject/gallery/AddImagesModal";

import AppLoadingSkeleton from "../components/reusablecomponents/AppLoadingSkeleton";
//  ==== HOOKS ====
import { UseFetchsingleProject } from "../hooks/project";
import {
  UseCreateNewNeed,
  UseCreateNewProjectGoal,
  UseCreateNewQuickLink,
  UseCreateProjectTop10,
  UseEditProject,
  UseEditQuickLink,
  UseFetchAllProjectKPI,
  UseFetchAllProjectTop10s,
  UseFetchAllQuickLinks,
  UseFetchMasterNeedCategories,
  UseFetchProjectAssumptions,
  UseFetchProjectDiscussion,
  UseFetchProjectGallery,
  UseFetchProjectGoals,
  UseFetchProjectNeeds,
  UseFetchProjectSubProjects,
  UseFetchProjectSuggestions,
} from "../hooks/project/singleProject";
import { UseFetchAllProjectCategories } from "../hooks/project/allProjects";
import useGetProfiles from "../hooks/profiles/useGetProfiles";
import { UseFetchAllProjectAssumptionsCategory } from "../hooks/Admin";

const SingleProjectContainer = ({
  fetchSingleProject,
  fetchProjects,
  allProfiles,
}) => {
  // ==== Include speeddial context ====
  const speeddial = useSpeedDialButtons();
  const { id } = useParams();
  const [tab, setTab] = useState("sum");
  const [showQuickLink, setShowQuickLink] = useState(false);
  const [ShowSubProject, setShowSubProject] = useState(false);
  const [showCollab, setShowCollab] = useState(false);
  const [editState, setEditState] = useState({
    project_goal: "",
    inputFields: [],
  });
  const [displayProjectformSub, setDisplayProjectformSub] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [showEditSdg, setShowEditSdg] = useState(false);
  const [editSdgs, setEditSdgs] = useState([]);
  const [swotLength, setSwotLength] = useState(0);
  const [bmcLength, setBmcLength] = useState(0);
  const [rightDrawer, setRightDrawer] = useState(false);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [mobileMenu, setMobileMenu] = useState({
    editProject: false,
    projectMenu: false,
  });
  const [quicklinkEdit, setQuicklinkEdit] = useState(false);
  const [editLink, setEditLinkInfo] = useState({});
  const [addImageModal, setAddImageModal] = useState(false);
  // ---- FETCHING OF PAGE DATA (RQ) ----
  const {
    data: projectData,
    error: projectError,
    isLoading: projectIsLoading,
  } = UseFetchsingleProject(id);

  const { data: projectCategoryData } = UseFetchAllProjectCategories();
  const { allProfilesData, allProfilesError, allProfilesLoading } =
    useGetProfiles();

  const {
    data: quickLinkData,
    error: quickLinkError,
    isLoading: quickLinkIsLoading,
  } = UseFetchAllQuickLinks(id, tab);

  const {
    data: goalsData,
    error: goalsError,
    isLoading: goalsIsLoading,
  } = UseFetchProjectGoals(id, tab);

  const {
    data: kpisData,
    error: kpisError,
    isLoading: kpisIsLoading,
  } = UseFetchAllProjectKPI(id, tab);

  const {
    data: assumptionsData,
    error: assumptionsError,
    isLoading: assumptionsIsLoading,
  } = UseFetchProjectAssumptions(id, tab);

  const {
    data: assCategories,
    isLoading: assCategoriesIsLoading,
    isError: assCategoriesIsErrors,
  } = UseFetchAllProjectAssumptionsCategory();

  const {
    data: needCategoriesData,
    error: needCategoriesError,
    isLoading: needCategoriesIsLoading,
  } = UseFetchMasterNeedCategories(tab);

  const {
    data: needData,
    error: needError,
    isLoading: needIsLoading,
  } = UseFetchProjectNeeds(tab, id);

  const {
    data: galleryData,
    error: galleryError,
    isLoading: galleryIsLoading,
  } = UseFetchProjectGallery(tab, id);

  const {
    data: discussionData,
    error: discussionError,
    isLoading: discussionIsLoading,
  } = UseFetchProjectDiscussion(tab, id);

  const {
    data: top10Data,
    error: top10Error,
    isLoading: top10IsLoading,
  } = UseFetchAllProjectTop10s(tab, id);

  const {
    data: suggestionData,
    error: suggestionError,
    isLoading: suggestionIsLoading,
  } = UseFetchProjectSuggestions(tab, id);

  const {
    data: subprojectData,
    error: subprojectError,
    isLoading: subprojectIsloading,
  } = UseFetchProjectSubProjects(tab, id);

  // ---- MUTATIONS REACT QUERY ----

  const closeEditModal = () => {
    speeddial.toggleProjectSpeedDialMenu("editproject", false);
  };
  const editProjectMutation = UseEditProject(closeEditModal);
  const handleUpdate = (formData) => {
    const data = {
      id,
      project: formData,
    };
    editProjectMutation.mutate(data);
  };

  const showQuickLinkModal = () => {
    setShowQuickLink(!showQuickLink);
  };
  const quickLinkMutation = UseCreateNewQuickLink(showQuickLinkModal);
  const handleQuickLinks = (data) => {
    const newQuickLink = {
      project_id: id,
      project_quick_link: data,
    };
    quickLinkMutation.mutate(newQuickLink);
  };

  const handleCloseQuickLinkEdit = () => {
    setQuicklinkEdit(false);
    setEditLinkInfo({});
  };
  const setEditLink = (link) => {
    setEditLinkInfo({ ...link });
  };
  const editQuickLinkMutation = UseEditQuickLink(handleCloseQuickLinkEdit);

  const closeGoalModal = () => {
    speeddial.toggleProjectSpeedDialMenu("goal", false);
  };
  const newProjectGoalMutation = UseCreateNewProjectGoal(closeGoalModal);
  const handleGoal = (goal) => {
    const newGoal = {
      id,
      data: goal,
    };
    newProjectGoalMutation.mutate(newGoal);
  };

  const createNewNeedMutation = UseCreateNewNeed();
  const handleCreateNeed = (data) => {
    createNewNeedMutation.mutate(data);
  };

  const closeTop10Modal = () => {
    speeddial.toggleProjectSpeedDialMenu("top10", false);
  };
  const createProjectTop10Mutation = UseCreateProjectTop10(closeTop10Modal);
  const handleCreatTop10 = (title, description, fields_attributes) => {
    const data = {
      top10Data: {
        title: title,
        description: description,
        project_fields_attributes: fields_attributes,
      },
      projectID: id,
    };
    createProjectTop10Mutation.mutate(data);
  };

  const projectSwotCheck = async () => {
    const { message } = await getAllSwot(id);
    message === "Swot found successfully" ? setSwotLength(1) : createSwot();
    return;
  };

  // ==== Create New Swot
  const createSwot = async () => {
    const myresults = await fetchSingleProject(id);
    const { payload } = myresults;
    const { result } = payload;
    const { project } = result;

    const message = await createNewSwot(id, project.name);
    if (message === "Swot created successfuly") {
      setSwotLength(1);
      return;
    }
  };

  // ==== Create New BMC
  const createNewProjectBmc = async (bmcDescription = "") => {
    const myresults = await fetchSingleProject(id);
    const { payload } = myresults;
    const { result } = payload;
    const { project } = result;

    try {
      const { message } = await createNewBmc(id, project.name, bmcDescription);
      if (message === "BCM created successfuly") {
        setBmcLength(1);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ==== CHECK IF THERE IS BMC ====
  const projectBmcCheck = async () => {
    const { message } = await getAllBmc(id);
    message === "BCM found successfully"
      ? setBmcLength(1)
      : createNewProjectBmc();
    return;
  };

  useEffect(() => {
    projectSwotCheck();
    projectBmcCheck();
  }, []);

  // ==== FETCH ALL PROJECTS =====
  const fetchAllProjects = async () => {
    const { payload } = await fetchProjects();
    if (payload.message !== "Projects fetched successfully")
      return NotificationManager.error(
        "Something went wrong kinldy refresh your browser"
      );

    setAllProjects(payload.result);
  };

  useEffect(() => {
    fetchAllProjects();
  }, []);

  const showCollabModal = () => {
    setShowCollab(!showCollab);
  };

  // ==== CLOSE MODAL SUBPROJECT
  const closeSubProject = () => {
    setShowSubProject(false);
  };

  // ==== FETCH ALL PROJECTS OPEN ITEMS ====
  const fetchAllProjectOpenItems = async () => {
    try {
      const data = await getAllProjectOpenItems(id);
      if (data.message === "Project open items fetched successfully") {
        setCurrentEvents([...data.result]);
      }
    } catch (error) {
      NotificationManager.error(
        "Something went wrong kindly check your internet connection and refresh"
      );
    }
  };

  useEffect(() => {
    fetchAllProjectOpenItems();
  }, []);

  const handleSDG = async (e) => {
    e.preventDefault();
    const { message, result } = await showProjectSdgs(id);
    if (message === "Sdgs fetched sucessfully") {
      setShowEditSdg(true);
      setEditSdgs(result);
      return;
    }
  };

  const showTabSelection = () => {
    if (!_.isEmpty(projectData)) {
      // --- SUMMARY DATA ----
      const { project, tags, created_by, project_collaborators, project_sdg } =
        projectData;

      switch (tab) {
        case "sum":
          return (
            <ProjectSummary
              project={project}
              tags={tags}
              creator={created_by}
              users={project_collaborators}
              date={project.created_at}
              showCollabModal={showCollabModal}
              showQuickLinkModal={showQuickLinkModal}
              projectSdgs={project_sdg}
              handleSDG={handleSDG}
            />
          );
        case "quick":
          return (
            <ProjectQuickLinks
              id={id}
              fetchSingleProject={fetchSingleProject}
              links={quickLinkData}
              showModal={showQuickLinkModal}
              setQuicklinkEdit={setQuicklinkEdit}
              setEditLink={setEditLink}
              quickLinkError={quickLinkError}
              quickLinkIsLoading={quickLinkIsLoading}
              quickLinkMutation={quickLinkMutation}
            />
          );
        case "goa":
          return (
            <ProjectGoals
              id={id}
              goals={goalsData}
              goalsIsLoading={goalsIsLoading}
              goalsError={goalsError}
              kpisIsLoading={kpisIsLoading}
              kpisError={kpisError}
              projectGoalKpis={kpisData}
              handleGoalSubmit={handleGoal}
              editState={editState}
              setEditState={setEditState}
              fetchSingleProject={fetchSingleProject}
              project={project}
              speedDialState={speeddial}
            />
          );
        case "ass":
          return (
            <ProjectAssumptions
              projectAssumption={assumptionsData}
              IsLoading={assumptionsIsLoading}
              isError={assumptionsError}
              projectID={id}
              allProfiles={allProfiles}
              speedDialState={speeddial}
            />
          );
        case "nee":
          return (
            <ProjectNeeds
              id={id}
              project={project}
              speedDialState={speeddial}
              needData={needData}
              needError={needError}
              needIsLoading={needIsLoading}
              needCategoriesData={needCategoriesData}
              needCategoriesIsLoading={needCategoriesIsLoading}
              needCategoriesError={needCategoriesError}
              createNewNeedMutation={createNewNeedMutation}
            />
          );
        case "gal":
          return (
            <ProjectGallery
              project={project}
              setAddImageModal={setAddImageModal}
              galleryData={galleryData}
              galleryError={galleryError}
              galleryIsLoading={galleryIsLoading}
            />
          );
        case "dis":
          return (
            <ProjectDiscussion
              projectDiscussion={discussionData}
              discussionIsLoading={discussionIsLoading}
              discussionError={discussionError}
              projectID={id}
              speedDialState={speeddial}
            />
          );
        case "top":
          return (
            <ProjectTop10Main
              id={id}
              project={project}
              topTens={top10Data}
              topTensIsLoading={top10IsLoading}
              topTensError={top10Error}
              speedDialState={speeddial}
            />
          );
        case "sug":
          return (
            <ProjectSuggestion
              id={id}
              suggestions={suggestionData}
              suggestionError={suggestionError}
              suggestionIsLoading={suggestionIsLoading}
              projectID={id}
            />
          );
        case "sub":
          return (
            <SubProject
              subProjects={subprojectData}
              subprojectError={subprojectError}
              subprojectIsloading={subprojectIsloading}
              setShowSubProject={setShowSubProject}
              setDisplayProjectformSub={setDisplayProjectformSub}
              categories={projectCategoryData}
              sdgs={project_sdg}
              project={project}
              speedDialState={speeddial}
              setTab={setTab}
            />
          );
        case "swo":
          return (
            <SwotContainer
              projectID={id}
              singleProject={project}
              setSwotLength={setSwotLength}
              project={project}
            />
          );
        case "bmc":
          return (
            <BmcContainer
              projectID={id}
              singleProject={project}
              setBmcLength={setBmcLength}
              project={project}
            />
          );

        case "cal":
          return (
            <ProjectCalender
              allProfiles={allProfiles}
              currentEvents={currentEvents}
              setCurrentEvents={setCurrentEvents}
              fetchAllProjectOpenItems={fetchAllProjectOpenItems}
            />
          );
        default:
          return (
            <ProjectSummary
              project={project}
              tags={tags}
              creator={created_by}
              users={project_collaborators}
              date={project.created_at}
              showCollabModal={showCollabModal}
              showQuickLinkModal={showQuickLinkModal}
              projectSdgs={project_sdg}
              handleSDG={handleSDG}
            />
          );
      }
    }
  };

  // <==== THIS IS FOR CLOSING THE EDIT MODAL ====>
  const renderEditModal = () => {
    if (!_.isEmpty(projectData)) {
      const { project, tags, project_sdg } = projectData;
      return (
        <>
          {speeddial.buttons.editProject ? (
            <EditProjectModal
              categories={projectCategoryData}
              project={project}
              proTags={tags}
              handleSubmit={handleUpdate}
              closeModal={closeEditModal}
              sdgs={project_sdg}
              // allSdgs={allSdgs}
              editProjectMutation={editProjectMutation}
            />
          ) : null}
        </>
      );
    }
  };

  const renderCollabModal = () => {
    if (!_.isEmpty(projectData)) {
      return (
        <ProjectCollabModal
          isOpen={showCollab}
          closeModal={showCollabModal}
          users={allProfilesData}
          allProfilesLoading={allProfilesLoading}
          allProfilesError={allProfilesError}
          projectData={projectData}
          project_id={id}
        />
      );
    }
  };

  const renderQuickLinkModal = () => {
    if (!_.isEmpty(projectData)) {
      return (
        <>
          <ProjectQuickLinkModal
            isOpen={showQuickLink}
            closeModal={showQuickLinkModal}
            handleSubmit={handleQuickLinks}
          />
        </>
      );
    }
  };

  const showProject = (projectData) => {
    return (
      <>
        <div className="main-body-area-single-project">
          <SideBar singleProject={projectData} setTab={setTab} tab={tab} />
          <div className="project-static-area-space">
            <TopProjectBar
              singleProject={projectData}
              showTabSelection={showTabSelection}
              tab={tab}
              users={projectData.project_collaborators}
              showCollabModal={showCollabModal}
              speedDialState={speeddial}
            />
            {/*<===== We shall add it back please dont delete this line of code =====>*/}
            {/* <MenuSpeedDial speedDialState={speeddial} /> */}
          </div>
        </div>
        {/* <==== Mobile Design Point ====> */}
        <div className="main-mobile-body-single-project-area">
          <div className="project-static-area-space">
            <TopProjectBar
              singleProject={projectData}
              showTabSelection={showTabSelection}
              tab={tab}
              users={projectData.project_collaborators}
              showCollabModal={showCollabModal}
              speedDialState={speeddial}
            />
          </div>
          <AppSideBarProject
            mobileMenu={mobileMenu}
            setTab={setTab}
            tab={tab}
            setMobileMenu={setMobileMenu}
          />
          <AppMobileBottomNavigation
            mobileMenu={mobileMenu}
            setMobileMenu={setMobileMenu}
            speedDialState={speeddial}
          />
        </div>
      </>
    );
  };

  // ---- TEST PRIVATE PROJECTS ----
  const showData = (projectData) => {
    if (!_.isEmpty(projectData)) {
      const isPrivate = projectData.project.is_private;
      if (
        isPrivate &&
        res.project.user_id !== res.current_user &&
        !res.collaborator_ids.includes(res.current_user)
      ) {
        return <PrivacyWarning title="Project" />;
      } else {
        return showProject(projectData);
      }
    }
  };

  const displayProjectFormClose = () => {
    setDisplayProjectformSub(false);
  };

  // ==== THIS IS FOR CREATING ASSUMPTIONS MODAL ====
  const closeAssumptionModal = () => {
    speeddial.toggleProjectSpeedDialMenu("assumptions", false);
  };

  // ==== THIS IS FOR CLOSING NEEDS MODAL ====
  const closeNeedsModal = () => {
    speeddial.toggleProjectSpeedDialMenu("need", false);
  };
  // ==== THIS IS FOR CLOSING THE DISCUSSION MODAL ====
  const closeDiscussionModal = () => {
    speeddial.toggleProjectSpeedDialMenu("discussion", false);
  };
  // ==== THIS IS FOR CLOSING THE SUGGESTION BOX MODAL ====
  const closeSuggestionBoxModal = () => {
    speeddial.toggleProjectSpeedDialMenu("suggestion", false);
  };

  // ==== THIS IS FOR CLOSING THE SUBPROJECT MODAL ====
  const closeSubprojectModal = () => {
    speeddial.toggleProjectSpeedDialMenu("subproject", false);
  };

  // ---- LOADING ----
  if (projectIsLoading) return <AppLoadingSkeleton />;

  // ---- AN ERROR OCCURRED DURING LOADING ----
  if (projectError)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          Something went wrong while fetching data. Kindly refresh and try again
        </Alert>
      </div>
    );
  // ---- PROJECT FOUND ----
  return (
    <>
      <AppRightDrawer
        rightDrawer={rightDrawer}
        setRightDrawer={setRightDrawer}
      />
      <NotificationContainer />
      <EditSdgs
        showEditSdg={showEditSdg}
        setShowEditSdg={setShowEditSdg}
        editSdgs={editSdgs}
        projectID={id}
        setEditSdgs={setEditSdgs}
      />
      {/* ==== THIS IS FOR CREATING NEW GOAL ==== */}
      <ProjectGoalsModal
        closeModal={closeGoalModal}
        id={id}
        handleSubmit={handleGoal}
        speedDialState={speeddial}
      />
      {/* ==== THIS IS FOR CREATING NEW ASSUMPTION ==== */}
      <CreateAssumption
        projectID={id}
        isOpen={speeddial.buttons.newAssumption}
        closeModal={closeAssumptionModal}
        assCategories={assCategories}
        assCategoriesIsLoading={assCategoriesIsLoading}
        assCategoriesIsErrors={assCategoriesIsErrors}
      />

      {/* ==== THIS IS FOR CREATING NEW NEED ==== */}
      <NeedModal
        categories={needCategoriesData}
        needCategoriesIsLoading={needCategoriesIsLoading}
        needCategoriesError={needCategoriesError}
        category={null}
        isOpen={speeddial.buttons.newNeed}
        closeModal={closeNeedsModal}
        handleSubmit={handleCreateNeed}
        proId={id}
      />

      {/* === GALLERY MODAL === */}
      <AddImagesModal
        addImageModal={addImageModal}
        setAddImageModal={setAddImageModal}
        galleryData={galleryData}
      />

      {/* ==== CREATE NEW TOP 10 ==== */}
      <ProjectTopTenModal
        closeModal={closeTop10Modal}
        isOpen={speeddial.buttons.newTop10}
        handleSubmit={handleCreatTop10}
        createProjectTop10Mutation={createProjectTop10Mutation}
      />

      {/* ==== CREATE NEW DISCUSSION ==== */}
      <CreateDiscussion
        isOpen={speeddial.buttons.newDiscussion}
        closeModal={closeDiscussionModal}
        projectID={id}
      />

      {/* ==== Edit Project Modal ==== */}
      {renderEditModal()}
      {/*==== THIS RENDERS COLLABORATION MODAL ==== */}
      {renderCollabModal()}
      {/*==== THIS RENDERS QUICK LINK MODAL ==== */}
      {renderQuickLinkModal()}
      {/* ==== Edit of quick links ====  */}
      <ProjectEditOfQuickLinkModal
        isOpen={quicklinkEdit}
        onClose={() => setQuicklinkEdit(false)}
        editLink={editLink}
        projectId={id}
        editQuickLinkMutation={editQuickLinkMutation}
      />
      <ToastContainer />
      <Navbar />
      <Modal
        show={displayProjectformSub}
        onHide={displayProjectFormClose}
        size="xl"
      >
        <Modal.Header
          className="text-white"
          style={{ backgroundColor: "#047C7C", marginBottom: "50px" }}
          closeButton
        >
          <Modal.Title>Create new project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectSingleForm
            categories={projectCategoryData}
            closeModal={displayProjectFormClose}
            createProject={createProject}
          />
        </Modal.Body>
      </Modal>

      {/* ==== SUB PROJECT MODAL ==== */}
      <SubProjectModal
        ShowSubProject={ShowSubProject}
        closeSubProject={closeSubProject}
        allProjects={allProjects}
        singleProjectID={id}
        setShowSubProject={setShowSubProject}
        isOpen={speeddial.buttons.newSubProject}
        closeModal={closeSubprojectModal}
      />

      {/* ---- add a loading container if the app is loading and an error bar if there is some error ---- */}
      {showData(projectData)}
    </>
  );
};

const mapStateToProps = (state) => ({
  singleProject: state.singleProject,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjects: () => dispatch(fetchProjects()),
  fetchSingleProject: (id) => dispatch(fetchSingleProject(id)),
  createProjectGoal: (projectID, data) =>
    dispatch(createProjectGoals(projectID, data)),
  createProjectComment: (id, data) => dispatch(createProjectComments(id, data)),
  fetchProjectComments: (id) => dispatch(fetchProjectComments(id)),
  fetchProjectCategory: () => dispatch(fetchProjectCategory()),
  updateProject: (id, data) => dispatch(updateProject(id, data)),
  createQuickLink: (id, data) => dispatch(createQuickLink(id, data)),
  fetchProjectsNeeds: (projectID) => dispatch(fetchProjectsNeeds(projectID)),
  createProjectNeeds: (projectID, data) =>
    dispatch(createProjectNeeds(projectID, data)),
  createProjectDiscussion: (projectID, data) =>
    dispatch(createProjectDiscussion(projectID, data)),
  allProfiles: () => dispatch(fetchAllProfile()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleProjectContainer);

/*eslint-enable */
