import React from "react";
import styles from "../css/main.module.css";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import Alert from "@mui/material/Alert";
import Typography from "@mui/joy/Typography";
import CircularProgress from "@mui/joy/CircularProgress";
import { useCountUp } from "use-count-up";
import ReadMoreReusable from "../../reusablecomponents/ReadMoreReusable";

const InfoCard = ({ dashboardContent }) => {
  const { count, percentage_engagement } = dashboardContent.user_activity_count;

  const { value } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: Math.round(percentage_engagement),
  });

  const displayInfoConditionally = () => {
    // eslint-disable-next-line eqeqeq
    if (value == 0) {
      return (
        <Alert severity="info">
          <ReadMoreReusable>
            {`Welcome to our platform! It looks like you don’t have any engagements yet. 
            We’re excited to see you get started! To earn your User Engagement Badge,
             explore the platform and join in the conversations.
              Your contributions matter, and we can’t wait to celebrate your achievements!`}
          </ReadMoreReusable>{" "}
        </Alert>
      );
    }
    if (value > 0 && value <= 25) {
      return (
        <Alert severity="error">
          {" "}
          <ReadMoreReusable>
            {`Hello! It seems your current engagement level is at ${value}%. You’re just ${
              100 - value
            }% away 
            from earning the Most Engaged User Badge! Join the fun—participate in discussions, explore,
             We’re here to support you on your journey to becoming one of our most engaged users!`}
          </ReadMoreReusable>
        </Alert>
      );
    }
    if (value > 25 && value <= 50) {
      return (
        <Alert severity="error">
          <ReadMoreReusable>
            {`Great job! Your engagement is improving, and you’re making steady progress. 
            You’re currently at ${value}% engagement—keep it up! You’re just ${
              100 - value
            }% away from achieving the Most Engaged User Badge. 
            Stay active, connect with others, and continue exploring the platform. You’re well on your way to reaching the top!`}
          </ReadMoreReusable>
        </Alert>
      );
    }
    if (value > 50 && value <= 75) {
      return (
        <Alert severity="info">
          {" "}
          <ReadMoreReusable>
            {`You're doing great! Your engagement is above average, and we’re thrilled to see your progress. Currently, you're at ${value}% engagement—fantastic work! To reach the Most Engaged User Badge, you’re just ${
              100 - value
            }% away.
             Keep exploring, contributing, and connecting on the platform.
              You’re so close to achieving this exciting milestone!`}
          </ReadMoreReusable>
        </Alert>
      );
    }
    if (value > 75 && value <= 99) {
      return (
        <Alert severity="success">
          {" "}
          <ReadMoreReusable>
            {`You're doing an excellent job! Your engagement is impressive at ${value}%—well done! To unlock the Most Engaged User Badge, you’re just ${
              100 - value
            }% away. Keep up the amazing momentum, stay active, and keep shining on the platform. You’re so close—let’s keep going strong!`}
          </ReadMoreReusable>
        </Alert>
      );
    }
    // eslint-disable-next-line eqeqeq
    if (value == 100) {
      return (
        <Alert severity="success">
          {" "}
          <ReadMoreReusable>
            {` Your engagement is excellent, You are the user who has the MOST
            ENGAGED USER BADGE. You are ${value}% engaged. Keep engaging to
            retain your badge. Great work!!!`}
          </ReadMoreReusable>
        </Alert>
      );
    }
  };

  return (
    <div className={styles.info_card_container}>
      <div className={styles.info_card_header}>
        {" "}
        <GroupWorkIcon
          sx={{
            fontSize: "32px",
            marginRight: "5px",
            fontWeight: "600",
          }}
        />
        <h4 className={styles.info_card_header_title}>Your total engagement</h4>
      </div>
      <div className={styles.info_card_body}>
        <div className={styles.card_info_header}>
          <div className={styles.number_value_container}>
            <h2 className={styles.number_value}>{count}</h2>
            <h5 className={styles.name_value}>Engagements</h5>
          </div>
          <div className={styles.progress_circular}>
            <CircularProgress
              size="lg"
              determinate
              value={parseInt(value)}
              color="primary"
            >
              <Typography>{value}%</Typography>
            </CircularProgress>
          </div>
        </div>
        <div className={styles.card_info_body}>
          {displayInfoConditionally()}
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
