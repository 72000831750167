// ==== THIS FILES HANDLES QUERIES FOR ALL PROJECTS CONTAINER ====
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  createProjectGoalObjective,
  createProjectGoalObjectiveKpi,
  editProjectGoalObjective,
  editProjectGoalObjectiveKpi,
  editSingleProject,
  fetchSingleProject,
} from "../../services/singleProject/singleProject";
import {
  createNewQuickLink,
  editProjectQuickLink,
  fetchAllQuickLinks,
} from "../../services/projects/ProjectQuickLink";
import {
  createGoalComment,
  createNewProjectGoal,
  createNewReplyOfReplyGoals,
  createProjectGoalCommentResponses,
  editCommentProjectGoal,
  editProjectGoal,
  editProjectGoalCommentResponses,
  editReplyOfReplyCommentGoals,
  fetchAllGoalComments,
  fetchAllGoals,
  fetchAllProjectKPI,
  unvoteProjectGoal,
  upvoteProjectGoal,
} from "../../services/projects/ProjectGoalService";
import {
  createNewAssumptionsReplyOfReply,
  createProjectAssumptions,
  editAssumptionReplyOfReplyComment,
  editProjectAssumption,
  fetchProjectAssumptions,
  unvoteProjectAssumption,
  upvoteProjectAssumption,
} from "../../services/projects/ProjectAssumptionService";
import {
  createMasterNeed,
  fetchMasterNeedsCategories,
  updateMasterNeed,
} from "../../services/masterNeeds/masterNeedsService";
import { fetchProjectNeeds } from "../../services/projects/projectsService";
import {
  createNewProjectGallery,
  fetchAllProjectGallery,
} from "../../services/gallery";
import {
  createProjectDiscussion,
  editProjectDiscussion,
  fetchAllProjectDiscussions,
} from "../../services/projects/ProjectDiscussionService";
import {
  createNewDiscussionLike,
  deleteNewDiscussionLike,
} from "../../services/projects/ProjectDiscussionLikesService";
import {
  createNewCommentResponse,
  createNewComments,
  editDiscussionCommentResponse,
  editProjectComment,
  getAllComments,
} from "../../services/projects/ProjectCommentsService";
import {
  createProjectTop10,
  deleteTop10,
  fetchProjectTopTens,
  updateProjectTop10,
} from "../../services/ProjectTop10/projectTop10";

import {
  createNewProjectSuggestion,
  createNewSuggestionReplyOfReply,
  editProjectSuggestion,
  editSuggestionReplyOfReplyComment,
  fetchProjectSuggestions,
  unvoteProjectSuggestion,
  upvoteProjectSuggestion,
} from "../../services/projects/ProjectSuggestionService";
import {
  getAllSubProjects,
  postSubProject,
  removeSubProject,
} from "../../services/subProjects/subProjectService";
import { addCollaborators } from "../../services/projects/ProjectCollaboratorsService";
import { follow, unFollow } from "../../services/favorites/favoriteService";
import {
  createNewReplyOfReply,
  editReplyOfReplyComment,
} from "../../services/projects/ProjectCommentReplyService";

import {
  createNewCommentReponseOfResponseForTop10Entry,
  editCommentReponseOfResponseForTop10Entry,
} from "../../services/ProjectTop10/projectTop10";

// ----- QUERY DATA ---

const UseFetchsingleProject = (id) => {
  const fetchProject = async () => {
    const { result } = await fetchSingleProject(id);
    return result;
  };
  return useQuery({
    queryKey: ["project", id],
    queryFn: fetchProject,
  });
};

const UseFetchAllQuickLinks = (id, tab) => {
  const fetchQuickLinks = async () => {
    const { result } = await fetchAllQuickLinks(id);
    return result;
  };

  return useQuery({
    queryKey: ["quickLinks"],
    queryFn: fetchQuickLinks,
    enabled: tab === "quick" ? true : false,
  });
};

const UseFetchProjectGoals = (id, tab) => {
  const fetchProjectGoals = async () => {
    const { result } = await fetchAllGoals(id);
    return result;
  };

  return useQuery({
    queryKey: ["goals"],
    queryFn: fetchProjectGoals,
    enabled: tab === "goa" ? true : false,
  });
};

const UseFetchAllProjectKPI = (id, tab) => {
  const fetchProjectGoalsKPIs = async () => {
    const { result } = await fetchAllProjectKPI(id);
    return result;
  };

  return useQuery({
    queryKey: ["kpis"],
    queryFn: fetchProjectGoalsKPIs,
    enabled: tab === "goa" ? true : false,
  });
};

const UseFetchAllGoalComments = (projectID, goalID) => {
  const data = {
    project_id: projectID,
    project_goal_id: goalID,
  };
  const fetchProjectGoalComments = async () => {
    const { result } = await fetchAllGoalComments(data);
    return result;
  };

  return useQuery({
    queryKey: ["goalComments"],
    queryFn: fetchProjectGoalComments,
    enabled: goalID !== null ? true : false,
  });
};

const UseFetchProjectAssumptions = (id, tab) => {
  const fetchAssumptions = async () => {
    const { result } = await fetchProjectAssumptions(id);
    return result;
  };

  return useQuery({
    queryKey: ["assumptions"],
    queryFn: fetchAssumptions,
    enabled: tab === "ass" ? true : false,
  });
};

// ---- NEEDS ----
const UseFetchMasterNeedCategories = (tab) => {
  const fetchCategories = async () => {
    const { result } = await fetchMasterNeedsCategories();
    return result;
  };

  return useQuery({
    queryKey: ["inventory_categories"],
    queryFn: fetchCategories,
    enabled: tab === "nee" ? true : false,
  });
};

const UseFetchProjectNeeds = (tab, id) => {
  const fetchNeeds = async () => {
    const { result } = await fetchProjectNeeds(id);
    return result;
  };

  return useQuery({
    queryKey: ["needs"],
    queryFn: fetchNeeds,
    enabled: tab === "nee" ? true : false,
  });
};

const UseFetchProjectGallery = (tab, id) => {
  const fetchGallery = async () => {
    const { result } = await fetchAllProjectGallery(id);
    return result;
  };

  return useQuery({
    queryKey: ["gallery"],
    queryFn: fetchGallery,
    enabled: tab === "gal" ? true : false,
  });
};

const UseFetchProjectDiscussion = (tab, id) => {
  const fetchDiscussion = async () => {
    const { result } = await fetchAllProjectDiscussions(id);
    return result;
  };

  return useQuery({
    queryKey: ["discussions"],
    queryFn: fetchDiscussion,
    enabled: tab === "dis" ? true : false,
  });
};

const UseFetchProjectDiscussionComments = (projectID, discussionID) => {
  const fetchDiscussionComments = async () => {
    const { result } = await getAllComments(projectID, discussionID);
    return result;
  };

  return useQuery({
    queryKey: ["project", projectID, "discussions", discussionID, "comments"],
    queryFn: fetchDiscussionComments,
    enabled: discussionID === null ? false : true,
  });
};

const UseFetchAllProjectTop10s = (tab, projectID) => {
  const fetchTop10s = async () => {
    const { result } = await fetchProjectTopTens(projectID);
    return result;
  };

  return useQuery({
    queryKey: ["top10s"],
    queryFn: fetchTop10s,
    enabled: tab === "top" ? true : false,
  });
};

const UseFetchProjectSuggestions = (tab, projectID) => {
  const fetchProjectSuggestion = async () => {
    const { result } = await fetchProjectSuggestions(projectID);
    return result;
  };

  return useQuery({
    queryKey: ["suggestions"],
    queryFn: fetchProjectSuggestion,
    enabled: tab === "sug" ? true : false,
  });
};

const UseFetchProjectSubProjects = (tab, projectID) => {
  const fetchProjectSubProjects = async () => {
    const { result } = await getAllSubProjects(projectID);
    return result;
  };

  return useQuery({
    queryKey: ["subprojects"],
    queryFn: fetchProjectSubProjects,
    enabled: tab === "sub" ? true : false,
  });
};

// ---- MUTATE DATA ----

const UseEditProject = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (projectData) => editSingleProject(projectData),
    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["project", `${variable.id}`],
      });
      fn();
    },
  });
};

const UseCreateNewQuickLink = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createNewQuickLink(data),

    onMutate: async (newQuickLink) => {
      await queryClient.cancelQueries("quickLinks");
      const previousQuickLinks = queryClient.getQueryData(["quickLinks"]);
      queryClient.setQueryData(["quickLinks"], (oldData) => [
        ...oldData,
        newQuickLink.project_quick_link,
      ]);

      fn();
      return { previousQuickLinks };
    },

    onError: (err, newTodo, context) => {
      queryClient.setQueryData(["quickLinks"], context.previousQuickLinks);
    },

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["quickLinks"],
      });
    },
  });
};

const UseEditQuickLink = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editProjectQuickLink(data),

    onMutate: async (editedQuickLink) => {
      fn();
      await queryClient.cancelQueries("quickLinks");
      const previousQuickLinks = queryClient.getQueryData(["quickLinks"]);

      queryClient.setQueryData(["quickLinks"], (oldData) =>
        oldData.map((link) =>
          link.id === editedQuickLink.id
            ? editedQuickLink.quikData.project_quick_link
            : link
        )
      );
      return { previousQuickLinks };
    },

    onError: (err, newTodo, context) => {
      queryClient.setQueryData(["quickLinks"], context.previousQuickLinks);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["quickLinks"],
      });
    },
  });
};

const UseCreateNewProjectGoal = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createNewProjectGoal(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goals"],
      });

      fn();
    },
  });
};

const UseEditProjectGoal = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editProjectGoal(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goals"],
      });

      fn();
    },
  });
};

const UseUpvoteProjectGoal = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => upvoteProjectGoal(data),

    onMutate: async (upvoteGoalData) => {
      await queryClient.cancelQueries("goals");
      const { upvote } = upvoteGoalData;
      const previousGoals = queryClient.getQueryData(["goals"]);

      queryClient.setQueryData(["goals"], (oldData) =>
        oldData.map((goal) =>
          goal.id === upvote.upvoteable_id
            ? {
                ...goal,
                likes: { upvoted: true, counter: goal.likes.counter + 1 },
              }
            : goal
        )
      );
      return { previousGoals };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData("goals", context.previousGoals);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["goals"],
      });
    },
  });
};

const UseUnVoteProjectGoal = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => unvoteProjectGoal(data),

    onMutate: async (unvoteData) => {
      await queryClient.cancelQueries("goals");
      const { upvoteable_id } = unvoteData;
      const previousGoals = queryClient.getQueryData(["goals"]);

      queryClient.setQueryData(["goals"], (oldData) =>
        oldData.map((goal) =>
          goal.id === upvoteable_id
            ? {
                ...goal,
                likes: { upvoted: false, counter: goal.likes.counter - 1 },
              }
            : goal
        )
      );
      return { previousGoals };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData("goals", context.previousGoals);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["goals"],
      });
    },
  });
};

const UseCreateNewProjectObjective = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createProjectGoalObjective(data),

    onMutate: async (objectiveData) => {
      fn();
      await queryClient.cancelQueries("goals");
      const previousGoals = queryClient.getQueryData(["goals"]);
      const { goal_id } = objectiveData;

      queryClient.setQueryData(["goals"], (oldData) =>
        oldData.map((goal) =>
          goal.id === goal_id
            ? {
                ...goal,
                project_goal_objectives: [
                  ...goal.project_goal_objectives,
                  { name: objectiveData.name },
                ],
              }
            : goal
        )
      );

      return { previousGoals };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData("goals", context.previousGoals);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["goals"],
      });
    },
  });
};

const UseEditProjectObjective = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editProjectGoalObjective(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goals"],
      });
      fn();
    },
  });
};

const UseCreateProjectKPI = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createProjectGoalObjectiveKpi(data),

    onMutate: async (kpiData) => {
      await queryClient.cancelQueries("kpis");
      const previousKpis = queryClient.getQueryData(["kpis"]);

      queryClient.setQueryData(["kpis"], (oldData) =>
        oldData.map((kpi) =>
          kpi.id === kpiData.objective_id
            ? {
                ...kpi,
                project_goal_objective_kpis: [
                  ...kpi.project_goal_objective_kpis,
                  {
                    name: kpiData.name,
                    project_id: kpiData.project_id,
                    project_goal_objective_id: kpiData.objective_id,
                  },
                ],
              }
            : kpi
        )
      );
      fn();
      return { previousKpis };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData("kpis", context.previousKpis);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["kpis"],
      });
    },
  });
};

const UseEditProjectKPI = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editProjectGoalObjectiveKpi(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["kpis"],
      });
      fn();
    },
  });
};

const UseCreateProjectGoalComment = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createGoalComment(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });

      fn();

      queryClient.invalidateQueries({
        queryKey: ["goals"],
      });
    },
  });
};

const UseEditProjectGoalComment = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editCommentProjectGoal(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });
      fn();
    },
  });
};

const UseUpvoteOfGoalComment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => upvoteProjectAssumption(data),
    onMutate: async (comment) => {
      await queryClient.cancelQueries("goalComments");
      const previousGoalComments = queryClient.getQueryData(["goalComments"]);
      const { upvote } = comment;

      queryClient.setQueryData(["goalComments"], (oldData) =>
        oldData.map((comment) =>
          comment.id === upvote.upvoteable_id
            ? {
                ...comment,
                likes: {
                  upvoted: true,
                  counter: comment.likes.counter + 1,
                },
              }
            : comment
        )
      );

      return { previousGoalComments };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData("goalComments", context.previousGoalComments);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });
    },
  });
};

const UseunVoteOfGoalComment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => unvoteProjectAssumption(data),
    onMutate: async (comment) => {
      await queryClient.cancelQueries("goalComments");
      const previousGoalComments = queryClient.getQueryData(["goalComments"]);
      const { upvoteable_id } = comment;

      queryClient.setQueryData(["goalComments"], (oldData) =>
        oldData.map((comment) =>
          comment.id === upvoteable_id
            ? {
                ...comment,
                likes: {
                  upvoted: false,
                  counter: comment.likes.counter - 1,
                },
              }
            : comment
        )
      );

      return { previousGoalComments };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData("goalComments", context.previousGoalComments);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });
    },
  });
};

const UseCreateOfGoalCommentReponses = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createProjectGoalCommentResponses(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });

      fn();
    },
  });
};

const UseEditOfGoalCommentReponses = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editProjectGoalCommentResponses(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });

      fn();
    },
  });
};

const UseUpvoteOfCommentResponse = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => upvoteProjectAssumption(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });
    },
  });
};

const UseUnVoteOfCommentResponse = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => unvoteProjectAssumption(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });
    },
  });
};

const UseCreateOfGoalCommentResponseOfResponse = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createNewReplyOfReplyGoals(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });
    },
  });
};

const UseEditOfGoalCommentReponsesOfResponses = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editReplyOfReplyCommentGoals(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });
    },
  });
};

const UseUpvoteOfCommentResponseOfResponse = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => upvoteProjectAssumption(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });
    },
  });
};

const UseUnVoteOfCommentResponseOfResponse = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => unvoteProjectAssumption(data),

    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["goalComments"],
      });
    },
  });
};

const UseCreateNewProjectAssumption = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createProjectAssumptions(data),

    onSuccess: (backendResponse, variable) => {
      fn(backendResponse.id);
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["assumptions"],
      });
    },
  });
};

const UseEditProjectAssumption = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editProjectAssumption(data),

    onSuccess: (backendResponse, variable) => {
      fn();
      queryClient.invalidateQueries({
        queryKey: ["assumptions"],
      });
    },
  });
};

const UseUpvoteProjectAssumption = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => upvoteProjectAssumption(data),

    onMutate: async (assumption) => {
      await queryClient.cancelQueries("assumptions");
      const previousProjectAssumption = queryClient.getQueryData([
        "assumptions",
      ]);

      const { upvote } = assumption;
      const { upvoteable_id } = upvote;

      queryClient.setQueryData(["assumptions"], (oldData) =>
        oldData.map((assumption) =>
          assumption.id === upvoteable_id
            ? {
                ...assumption,
                likes: {
                  upvoted: true,
                  counter: assumption.likes.counter + 1,
                },
              }
            : assumption
        )
      );
      return { previousProjectAssumption };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData(
        "assumptions",
        context.previousProjectAssumption
      );
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["assumptions"],
      });
    },
  });
};

const UseUnvoteProjectAssumption = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => unvoteProjectAssumption(data),

    onMutate: async (assumption) => {
      await queryClient.cancelQueries("assumptions");
      const previousProjectAssumption = queryClient.getQueryData([
        "assumptions",
      ]);

      const { upvoteable_id } = assumption;
      queryClient.setQueryData(["assumptions"], (oldData) =>
        oldData.map((assumption) =>
          assumption.id === upvoteable_id
            ? {
                ...assumption,
                likes: {
                  upvoted: false,
                  counter: assumption.likes.counter - 1,
                },
              }
            : assumption
        )
      );
      return { previousProjectAssumption };
    },

    onError: (error, variables, context) => {
      queryClient.setQueryData(
        "assumptions",
        context.previousProjectAssumption
      );
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["assumptions"],
      });
    },
  });
};

const UseCreateNewAssumptionsCommentReplyOfReply = (fn) => {
  return useMutation({
    mutationFn: async (data) => createNewAssumptionsReplyOfReply(data),
    onSuccess: () => {
      fn();
    },
  });
};

const UseEditAssumptionsCommentReplyOfReply = (fn) => {
  return useMutation({
    mutationFn: async (data) => editAssumptionReplyOfReplyComment(data),
    onSuccess: () => {
      fn();
    },
  });
};

const UseUpvoteOfAssumptionCommentReplyOfReply = (fn) => {
  return useMutation({
    mutationFn: async (data) => upvoteProjectAssumption(data),
    onSuccess: () => {
      fn();
    },
  });
};

const UseUnvoteOfAssumptionCommentReplyOfReply = (fn) => {
  return useMutation({
    mutationFn: async (data) => unvoteProjectAssumption(data),
    onSuccess: () => {
      fn();
    },
  });
};

// --- NEEDS ---
const UseCreateNewNeed = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createMasterNeed(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["needs"],
      });
    },
  });
};

const UseEditProjectNeed = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => updateMasterNeed(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["needs"],
      });
    },
  });
};

const UseCreateNewProjectGallery = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createNewProjectGallery(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["gallery"],
      });
      fn();
    },
  });
};

const UseCreateNewProjectDiscussion = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createProjectDiscussion(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["discussions"],
      });
      fn();
    },
  });
};

const UseEditProjectDiscussion = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editProjectDiscussion(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["discussions"],
      });
      fn();
    },
  });
};

const UseCreateNewDiscussionUpvote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createNewDiscussionLike(data),
    onMutate: async (discussion) => {
      await queryClient.cancelQueries("discussions");
      const previousProjectDiscussion = queryClient.getQueryData([
        "discussions",
      ]);
      const { discussionID } = discussion;
      queryClient.setQueryData(["discussions"], (oldData) =>
        oldData.map((discussion) =>
          discussion.id === discussionID
            ? {
                ...discussion,
                likes: {
                  upvoted: true,
                  counter: discussion.likes.counter + 1,
                },
              }
            : discussion
        )
      );
      return { previousProjectDiscussion };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        "discussions",
        context.previousProjectDiscussion
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["discussions"],
      });
    },
  });
};

const UseUnvoteDiscussion = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => deleteNewDiscussionLike(data),
    onMutate: async (discussion) => {
      await queryClient.cancelQueries("discussions");
      const previousProjectDiscussion = queryClient.getQueryData([
        "discussions",
      ]);
      const { discussionID } = discussion;
      queryClient.setQueryData(["discussions"], (oldData) =>
        oldData.map((discussion) =>
          discussion.id === discussionID
            ? {
                ...discussion,
                likes: {
                  upvoted: false,
                  counter: discussion.likes.counter - 1,
                },
              }
            : discussion
        )
      );
      return { previousProjectDiscussion };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        "discussions",
        context.previousProjectDiscussion
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["discussions"],
      });
    },
  });
};

const UseCreateNewDiscussionComment = (projectID, discussionID, fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createNewComments(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "project",
          projectID,
          "discussions",
          discussionID,
          "comments",
        ],
      });
      fn();
    },
  });
};

const UseEditDiscussionComment = (projectID, discussionID) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editProjectComment(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "project",
          projectID,
          "discussions",
          discussionID,
          "comments",
        ],
      });
    },
  });
};

const UseUpvoteOfDiscussionComment = (projectID, discussionID) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => upvoteProjectAssumption(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "project",
          projectID,
          "discussions",
          discussionID,
          "comments",
        ],
      });
    },
  });
};

const UseUnvoteOfDiscussionComment = (projectID, discussionID) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => unvoteProjectAssumption(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "project",
          projectID,
          "discussions",
          discussionID,
          "comments",
        ],
      });
    },
  });
};

const UseCreateDiscussionCommentResponse = (projectID, discussionID) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => createNewCommentResponse(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "project",
          projectID,
          "discussions",
          discussionID,
          "comments",
        ],
      });
    },
  });
};

const UseEditDiscussionCommentResponse = (projectID, discussionID) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => editDiscussionCommentResponse(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "project",
          projectID,
          "discussions",
          discussionID,
          "comments",
        ],
      });
    },
  });
};

const UseUpvoteDiscussionCommentResponse = (projectID, discussionID) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => upvoteProjectAssumption(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "project",
          projectID,
          "discussions",
          discussionID,
          "comments",
        ],
      });
    },
  });
};

const UseUnvoteDiscussionCommentResponse = (projectID, discussionID) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => unvoteProjectAssumption(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "project",
          projectID,
          "discussions",
          discussionID,
          "comments",
        ],
      });
    },
  });
};

const UseCreateNewDiscussionCommentReplyOfReply = (projectID, discussionID) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => createNewReplyOfReply(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "project",
          projectID,
          "discussions",
          discussionID,
          "comments",
        ],
      });
    },
  });
};

const UseEditDiscussionCommentReplyOfReply = (projectID, discussionID) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => editReplyOfReplyComment(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "project",
          projectID,
          "discussions",
          discussionID,
          "comments",
        ],
      });
    },
  });
};

const UseUpvoteDiscussionCommentReplyOfReply = (projectID, discussionID) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => upvoteProjectAssumption(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "project",
          projectID,
          "discussions",
          discussionID,
          "comments",
        ],
      });
    },
  });
};

const UseUnvoteDiscussionCommentReplyOfReply = (projectID, discussionID) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => unvoteProjectAssumption(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "project",
          projectID,
          "discussions",
          discussionID,
          "comments",
        ],
      });
    },
  });
};

const UseCreateProjectTop10 = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => createProjectTop10(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["top10s"],
      });
      fn();
    },
  });
};

const UseUpdateProjectTop10 = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => updateProjectTop10(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["top10s"],
      });

      fn();
    },
  });
};

const UseDeleteProjectTop10 = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => deleteTop10(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["top10s"],
      });

      fn();
    },
  });
};

const UseCreateNewTop10CommentResponseOfResponse = (fn) => {
  return useMutation({
    mutationFn: async (data) =>
      createNewCommentReponseOfResponseForTop10Entry(data),
    onSuccess: () => {
      fn();
    },
  });
};

const UseEditTop10CommentResponseOfResponse = (fn) => {
  return useMutation({
    mutationFn: async (data) => editCommentReponseOfResponseForTop10Entry(data),
    onSuccess: () => {
      fn();
    },
  });
};

const UseUpvoteOfTop10CommentResponseOfResponse = (fn) => {
  return useMutation({
    mutationFn: async (data) => upvoteProjectAssumption(data),
    onSuccess: () => {
      fn();
    },
  });
};

const UseUnvoteOfTop10CommentResponseOfResponse = (fn) => {
  return useMutation({
    mutationFn: async (data) => unvoteProjectAssumption(data),
    onSuccess: () => {
      fn();
    },
  });
};

const UseCreateNewSuggestion = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => createNewProjectSuggestion(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["suggestions"],
      });
      fn();
    },
  });
};

const UseEditProjectSuggestion = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => editProjectSuggestion(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["suggestions"],
      });
      fn();
    },
  });
};

const UseUpvoteOfProjectSuggestion = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => upvoteProjectSuggestion(data),
    onMutate: async (suggestion) => {
      await queryClient.cancelQueries("suggestions");
      const previousProjectSuggestion = queryClient.getQueryData([
        "suggestions",
      ]);

      const { upvoteable_id } = suggestion.upvote;

      queryClient.setQueryData(["suggestions"], (oldData) =>
        oldData.map((suggestion) =>
          suggestion.id === upvoteable_id
            ? {
                ...suggestion,
                likes: {
                  upvoted: true,
                  counter: suggestion.likes.counter + 1,
                },
              }
            : suggestion
        )
      );
      return { previousProjectSuggestion };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        "suggestions",
        context.previousProjectSuggestion
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["suggestions"],
      });
    },
  });
};

const UseDownVoteProjectSuggestion = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => unvoteProjectSuggestion(data),
    onMutate: async (suggestion) => {
      await queryClient.cancelQueries("suggestions");
      const previousProjectSuggestion = queryClient.getQueryData([
        "suggestions",
      ]);
      const { upvoteable_id } = suggestion;

      queryClient.setQueryData(["suggestions"], (oldData) =>
        oldData.map((suggestion) =>
          suggestion.id === upvoteable_id
            ? {
                ...suggestion,
                likes: {
                  upvoted: false,
                  counter: suggestion.likes.counter - 1,
                },
              }
            : suggestion
        )
      );
      return { previousProjectSuggestion };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(
        "suggestions",
        context.previousProjectSuggestion
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["suggestions"],
      });
    },
  });
};

const UseCreateProjectSuggestionReplyOfReply = (fn) => {
  return useMutation({
    mutationFn: async (data) => createNewSuggestionReplyOfReply(data),
    onSuccess: () => {
      fn();
    },
  });
};

const UseUpvoteOfProjectSuggestionResponseOfResponse = (fn) => {
  return useMutation({
    mutationFn: async (data) => upvoteProjectAssumption(data),
    onSuccess: () => {
      fn();
    },
  });
};

const UseUnvoteOfProjectSuggestionResponseOfResponse = (fn) => {
  return useMutation({
    mutationFn: async (data) => unvoteProjectAssumption(data),
    onSuccess: () => {
      fn();
    },
  });
};

const UseEditProjectSuggestionReplyOfReply = (fn) => {
  return useMutation({
    mutationFn: async (data) => editSuggestionReplyOfReplyComment(data),
    onSuccess: () => {
      fn();
    },
  });
};

const UseAddSubProject = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => postSubProject(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["subprojects"],
      });
      fn();
    },
  });
};

const UseDeleteProjectSubProjects = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => removeSubProject(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["subprojects"],
      });
    },
  });
};

const UseAddCollaboratorsToProject = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => addCollaborators(data),
    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["project", `${variable.project_id}`],
      });
      fn();
    },
  });
};

const UseCreateSingleProjectFollow = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (projectID) => follow(projectID),
    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["project", `${variable}`],
      });
    },
  });
};

const UseDeleteSingleProjectFollow = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (projectID) => unFollow(projectID),
    onSuccess: (backendResponse, variable) => {
      queryClient.invalidateQueries({
        queryKey: ["project", `${variable}`],
      });
    },
  });
};

export {
  UseFetchsingleProject,
  UseEditProject,
  UseFetchAllQuickLinks,
  UseCreateNewQuickLink,
  UseEditQuickLink,
  UseFetchProjectGoals,
  UseCreateNewProjectGoal,
  UseEditProjectGoal,
  UseUpvoteProjectGoal,
  UseUnVoteProjectGoal,
  UseCreateNewProjectObjective,
  UseEditProjectObjective,
  UseFetchAllProjectKPI,
  UseCreateProjectKPI,
  UseEditProjectKPI,
  UseCreateProjectGoalComment,
  UseEditProjectGoalComment,
  UseFetchAllGoalComments,
  UseUpvoteOfGoalComment,
  UseunVoteOfGoalComment,
  UseCreateOfGoalCommentReponses,
  UseEditOfGoalCommentReponses,
  UseUpvoteOfCommentResponse,
  UseUnVoteOfCommentResponse,
  UseFetchProjectAssumptions,
  UseCreateNewProjectAssumption,
  UseEditProjectAssumption,
  UseUpvoteProjectAssumption,
  UseUnvoteProjectAssumption,
  UseFetchMasterNeedCategories,
  UseFetchProjectNeeds,
  UseCreateNewNeed,
  UseEditProjectNeed,
  UseFetchProjectGallery,
  UseCreateNewProjectGallery,
  UseFetchProjectDiscussion,
  UseCreateNewProjectDiscussion,
  UseCreateNewDiscussionUpvote,
  UseUnvoteDiscussion,
  UseEditProjectDiscussion,
  UseFetchProjectDiscussionComments,
  UseCreateNewDiscussionComment,
  UseEditDiscussionComment,
  UseUpvoteOfDiscussionComment,
  UseUnvoteOfDiscussionComment,
  UseCreateDiscussionCommentResponse,
  UseEditDiscussionCommentResponse,
  UseUpvoteDiscussionCommentResponse,
  UseUnvoteDiscussionCommentResponse,
  UseCreateNewDiscussionCommentReplyOfReply,
  UseEditDiscussionCommentReplyOfReply,
  UseUpvoteDiscussionCommentReplyOfReply,
  UseUnvoteDiscussionCommentReplyOfReply,
  UseFetchAllProjectTop10s,
  UseCreateProjectTop10,
  UseUpdateProjectTop10,
  UseDeleteProjectTop10,
  UseFetchProjectSuggestions,
  UseCreateNewSuggestion,
  UseEditProjectSuggestion,
  UseUpvoteOfProjectSuggestion,
  UseDownVoteProjectSuggestion,
  UseFetchProjectSubProjects,
  UseDeleteProjectSubProjects,
  UseAddSubProject,
  UseAddCollaboratorsToProject,
  UseCreateSingleProjectFollow,
  UseDeleteSingleProjectFollow,
  UseCreateNewAssumptionsCommentReplyOfReply,
  UseEditAssumptionsCommentReplyOfReply,
  UseUpvoteOfAssumptionCommentReplyOfReply,
  UseUnvoteOfAssumptionCommentReplyOfReply,
  UseCreateOfGoalCommentResponseOfResponse,
  UseEditOfGoalCommentReponsesOfResponses,
  UseUpvoteOfCommentResponseOfResponse,
  UseUnVoteOfCommentResponseOfResponse,
  UseCreateNewTop10CommentResponseOfResponse,
  UseEditTop10CommentResponseOfResponse,
  UseUpvoteOfTop10CommentResponseOfResponse,
  UseUnvoteOfTop10CommentResponseOfResponse,
  UseCreateProjectSuggestionReplyOfReply,
  UseEditProjectSuggestionReplyOfReply,
  UseUpvoteOfProjectSuggestionResponseOfResponse,
  UseUnvoteOfProjectSuggestionResponseOfResponse,
};
