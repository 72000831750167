import React from "react";
import { useHistory } from "react-router-dom";
import nature from "../../images/val.png";
import "../../stylesheets/landingPage.css";
import ideaImage from "../../images/ideaImage.png";
import top10 from "../../images/top10.png";
import projects from "../../assets/img/projects_ladning_page.jpeg";
import FlagSystem from "../../assets/img/redflag_system.jpg";
import network from "../../assets/img/networks_image.webp";

const AboutUs = () => {
  let history = useHistory();

  const navigateToTop10 = () => {
    history.push("/categories");
  };

  const navigateSuggestionBox = () => {
    history.push("/suggestion");
  };

  const navigateToProjects = () => {
    history.push("/projects");
  };

  const redFlagSystem = () => {
    history.push("/openitems");
  };

  const navigateToNetworks = () => {
    history.push("/networks");
  };
  return (
    <div className="card border-0">
      <h2
        className="card-title mb-5  font-weight-bold text-center"
        style={{
          textTransform: "uppercase",
          color: "black",
          fontWeight: "800",
          textDecoration: "underline",
          marginTop: "15px", // Add some space between the title and the content
        }}
      >
        What we are building
      </h2>
      <div className="row no-gutters">
        <div
          className="col-lg-6"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img src={nature} alt="" className="img-fluid abt-img" />
        </div>
        <div className="col-lg-6">
          <div className="card-body abt-card-text">
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
              onClick={() => navigateToTop10()}
            >
              <img
                src={top10}
                alt="The top 10 system"
                style={{
                  height: "90px",
                  marginRight: "20px",
                }}
              />
              <h5
                className="card-title"
                style={{
                  position: "relative",
                  top: "30px",

                  fontWeight: "800",
                }}
              >
                Top10 SYSTEM
              </h5>
            </div>

            <p className="card-text">
              Prioritize what matters most. The Top10 System is designed to
              enhance the quality, quantity, and effectiveness of collective
              engagement. It’s a revolutionary tool for ranking, refining, and
              executing good ideas. Want to know more? Stay tuned—your next big
              breakthrough could start here.
            </p>

            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
              onClick={() => navigateToProjects()}
            >
              <img
                src={projects}
                alt="projects"
                style={{
                  height: "90px",
                  marginRight: "20px",
                }}
              />
              <h5
                className="card-title"
                style={{
                  position: "relative",
                  top: "30px",
                  textTransform: "uppercase",
                  fontWeight: "800",
                }}
              >
                Projects System
              </h5>
            </div>

            <p className="card-text">
              At TUC, projects are more than tasks—they’re the foundation for
              real-world impact. With TUC’s project tools, you can connect with
              global changemakers, transform your vision into actionable plans,
              and track progress every step of the way. Whether you’re launching
              a local initiative or spearheading a global movement, TUC provides
              the support and framework to ensure your ideas reach their Full
              Potential. Ready to turn your vision into action? Create a profile
              today and join the growing community of innovators and doers!
            </p>

            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
              onClick={() => navigateToNetworks()}
            >
              <img
                src={network}
                alt="projects"
                style={{
                  height: "90px",
                  marginRight: "20px",
                }}
              />
              <h5
                className="card-title"
                style={{
                  position: "relative",
                  top: "30px",
                  textTransform: "uppercase",
                  fontWeight: "800",
                }}
              >
                Network System
              </h5>
            </div>

            <p className="card-text">
              Great things happen when people connect. TUC’s Network feature
              bridges the gap between individuals, and projects communities and
              organizations with the simple vision to collaborate on shared
              goals more efficiently. Whether you’re looking to attract more
              like-minded people for your cause or amplify the reach of your
              current projects, TUC’s Networks will empower you to make an even
              Bigger Splash with a global ripple effect that impacts future
              generations from your already amazing efforts. Looking to expand
              your connections? Build your network today and take collaboration
              to the next level.
            </p>

            <div
              onClick={navigateSuggestionBox}
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <img
                src={ideaImage}
                alt="world connected suggestion box"
                style={{
                  height: "90px",
                  marginRight: "20px",
                }}
              />
              <h5
                className="card-title"
                style={{
                  position: "relative",
                  top: "30px",
                  textTransform: "uppercase",
                  fontWeight: "800",
                }}
              >
                The World's Most Connected Suggestion Box
              </h5>
            </div>
            <p className="card-text">
              Every great change starts with one idea. TUC’s Suggestion Box
              ensures those ideas—whether they’re solutions, acts of kindness,
              or innovations—don’t get lost. Instead, they’re connected to the
              tools and people needed to bring them to life. Got an idea that
              could change the world? It’s time to share it.
            </p>

            <div
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
              onClick={() => redFlagSystem()}
            >
              <img
                src={FlagSystem}
                alt="redflag system"
                style={{
                  height: "90px",
                  marginRight: "20px",
                }}
              />
              <h5
                className="card-title"
                style={{
                  position: "relative",
                  top: "30px",
                  textTransform: "uppercase",
                  fontWeight: "800",
                }}
              >
                RedFlag System
              </h5>
            </div>

            <p className="card-text">
              Spot issues before they become obstacles. The Red Flag System is
              TUC’s accountability tool for identifying challenges, gaps, or
              potential roadblocks in projects and discussions to turn them into
              practical learning experiences and possibility opportunities.
              TUC’s Regenerative Systems are just about keeping your initiatives
              on track, they leverage your problems to help create generational
              wealth. Want to create Sustainable solutions that stick? Learn how
              TUC’s Red Flag’s are practical learning Opportunities, and
              possibly can create Opportunities that result in Generational
              Wealth
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
