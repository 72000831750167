/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from "react";
import nocores from "../../assets/nocorevalues.png";
import { NotificationContainer } from "react-notifications";
import { NotificationManager } from "react-notifications";
import EditAssumption from "./projectAssumptions/EditAssumption";
import AppReusableCard from "./globals/AppReusableCard";
import {
  createProjectAssumptionComment,
  createProjectAssumptionCommentResponses,
  editProjectAssumptionComment,
  editProjectAssumptionCommentResponses,
  fetchProjectAssumptionComment,
  unvoteProjectAssumption,
  upvoteProjectAssumption,
} from "../../services/projects/ProjectAssumptionService";
import AppReusableDisplayUpvotedProfiles from "./globals/AppReusableDisplayUpvotedProfiles";
import ShareAssumptionModal from "./globals/ShareAssumptionModal";
import { Button, Chip, Divider, Tabs } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import _ from "lodash";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// ==== Comment Box
import CommentBox from "./globals/CommentBox";
import {
  UseCreateNewAssumptionsCommentReplyOfReply,
  UseEditAssumptionsCommentReplyOfReply,
  UseUnvoteOfAssumptionCommentReplyOfReply,
  UseUnvoteProjectAssumption,
  UseUpvoteOfAssumptionCommentReplyOfReply,
  UseUpvoteProjectAssumption,
} from "../../hooks/project/singleProject";
import { UseFetchAllProjectAssumptionsCategory } from "../../hooks/Admin";

const ProjectAssumptions = ({
  projectAssumption,
  IsLoading,
  isError,
  projectID,
  allProfiles,
  speedDialState,
}) => {
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const [openEditModal, setOpenEditModal] = useState(false);
  const closeEditModal = () => setOpenEditModal(false);
  const [assumptionID, setAssumptionID] = useState(null);
  const [editState, setEditState] = useState({});
  const [commentModal, setCommentModal] = useState(false);
  // ==== OPEN UPVOTES PROFILES ====
  const [votersToggleDrawer, setVotersToggleDrawer] = useState(false);
  const [votersProfiles, setVotersProfiles] = useState([]);
  const [drawerTitle, setDrawerTitle] = useState("");
  // ===== SHARE MODAL ====
  const [openShareModal, setOpenShareModal] = useState(false);
  const [profiles, setProfiles] = useState([]);
  // ==== DISPLAY THE DISCUSSIONS CONDITIONALLY ====
  const [oneAssumption, setOneAssumption] = useState({});

  // ==== COMMENT BOX ====
  const [commentBoxDetail, setCommentBoxDetail] = useState("");
  // ====  EFFICIENTRY HANDLE THE SUBMIT BUTTON ====
  const [submitButton, setSubmitButton] = useState(false);
  const [editSubmitButton, setEditSubmitButton] = useState(false);
  const [assumptionSelection, setAssumptionSelection] = useState({
    idea: "",
    purpose: "",
    assumption: "",
  });
  const [assumptionComments, setAssumptionComments] = useState([]);
  const [likeButton, setLikeButton] = useState(false);

  // --- CATEGORIES SELECTION ---
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // ---- SUB CATEGORY SELECTION ----
  const [subCategoryValue, setSubCategoryValue] = useState([]);
  const [subCategorySelection, setSubCategorySelection] = useState(null);
  const handleSubCategoryChange = (event) => {
    setSubCategorySelection(event.target.value);
  };

  useEffect(() => {
    fetchAllProfiles();
  }, []);

  const fetchAllProfiles = async () => {
    const { type, payload } = await allProfiles();
    if (type === "FETCH_ALL_PROFILES_SUCCESS") return setProfiles([...payload]);
  };

  // ---- RQ MUTATIONS ---
  const UpvoteAssumptionMutation = UseUpvoteProjectAssumption();
  const UnvoteAssumptionMutation = UseUnvoteProjectAssumption();
  const {
    data: assCategories,
    isLoading: assCategoriesIsLoading,
    isError: assCategoriesIsErrors,
  } = UseFetchAllProjectAssumptionsCategory();

  // ==== HANDLE LIKE ACTION ====
  const handleLikeAction = async (ass) => {
    const data = {
      upvote: {
        upvoteable_id: ass.id,
        upvoteable_type: "ProjectAssumption",
      },
    };

    UpvoteAssumptionMutation.mutate(data);
  };

  //  ==== HANDLE DISLIKE ACTION
  const handleDislikeAction = async (ass) => {
    const data = {
      upvoteable_id: ass.id,
      upvoteable_type: "ProjectAssumption",
    };
    UnvoteAssumptionMutation.mutate(data);
  };

  //   ==== OPENING THE SHARE MODAL ====
  const handleOpenShareModal = (value) => {
    setOneAssumption({ ...value });
    setOpenShareModal(true);
  };

  const handleOpenCommentModal = (id, comments, data) => {
    setAssumptionID(id);
    setAssumptionComments([...comments]);
    setAssumptionSelection({
      idea: data?.idea || "",
      purpose: data?.purpose || "",
      assumption: data?.assumption || "",
    });
    setCommentModal(true);
  };

  // ===== HANDLE EDIT ACTION ====
  const handleEditAction = (value) => {
    setAssumptionID(value.id);
    setEditState({
      assumption: value.assumption,
      idea: value.idea,
      purpose: value.purpose,
    });
    setOpenEditModal(true);
  };

  // ==== HANDLE DISPLAY OF THE LIST IN ASSUMPTIONS ARRAY
  const diplayListItems = () => {
    console.log(projectAssumption);
    if (value === 0) {
      const results = projectAssumption.map((value) => {
        return (
          <div key={value.id} className="assumption-card-cont">
            <AppReusableCard
              id={value.id}
              idea={value.idea}
              purpose={value.purpose}
              inputFieldName="Assumption"
              inputField={value.assumption}
              creatorFirstName={value.creator_profile.first_name}
              creatorLastName={value.creator_profile.last_name}
              createdAt={value.created_at}
              editAction={() => handleEditAction(value)}
              openCommentModal={handleOpenCommentModal}
              creatorImage={value.avatar}
              actionItems={true}
              upvoted_by={value.upvoted_by}
              shared_with={value.shared_with}
              votes={value.likes.counter}
              userLiked={value.likes.upvoted}
              likeAction={() => handleLikeAction(value)}
              unLikeAction={() => handleDislikeAction(value)}
              setVotersToggleDrawer={setVotersToggleDrawer}
              setVotersProfiles={setVotersProfiles}
              setDrawerTitle={setDrawerTitle}
              openShareModal={() => handleOpenShareModal(value)}
              comments={value.comments}
              data={value}
              likeButton={likeButton}
            />
          </div>
        );
      });

      return results;
    }
    if (value !== 0 && subCategorySelection === null) {
      const filteredAssumptions = [];

      projectAssumption.forEach((assumption) => {
        if (_.includes(_.map(assumption.assumption_categories, "id"), value)) {
          filteredAssumptions.push(assumption);
        }
      });

      const results = filteredAssumptions.map((value) => {
        return (
          <div key={value.id} className="assumption-card-cont">
            <AppReusableCard
              id={value.id}
              idea={value.idea}
              purpose={value.purpose}
              inputFieldName="Assumption"
              inputField={value.assumption}
              creatorFirstName={value.creator_profile.first_name}
              creatorLastName={value.creator_profile.last_name}
              createdAt={value.created_at}
              editAction={() => handleEditAction(value)}
              openCommentModal={handleOpenCommentModal}
              creatorImage={value.avatar}
              actionItems={true}
              upvoted_by={value.upvoted_by}
              shared_with={value.shared_with}
              votes={value.likes.counter}
              userLiked={value.likes.upvoted}
              likeAction={() => handleLikeAction(value)}
              unLikeAction={() => handleDislikeAction(value)}
              setVotersToggleDrawer={setVotersToggleDrawer}
              setVotersProfiles={setVotersProfiles}
              setDrawerTitle={setDrawerTitle}
              openShareModal={() => handleOpenShareModal(value)}
              comments={value.comments}
              data={value}
              likeButton={likeButton}
            />
          </div>
        );
      });

      return results;
    }
    if (value !== 0 && subCategorySelection !== null) {
      const filteredAssumptions = [];
      projectAssumption.forEach((assumption) => {
        if (
          _.includes(_.map(assumption.assumption_categories, "id"), value) &&
          assumption?.assumption_sub_category?.id === subCategorySelection
        ) {
          filteredAssumptions.push(assumption);
        }
      });

      const results = filteredAssumptions.map((value) => {
        return (
          <div key={value.id} className="assumption-card-cont">
            <AppReusableCard
              id={value.id}
              idea={value.idea}
              purpose={value.purpose}
              inputFieldName="Assumption"
              inputField={value.assumption}
              creatorFirstName={value.creator_profile.first_name}
              creatorLastName={value.creator_profile.last_name}
              createdAt={value.created_at}
              editAction={() => handleEditAction(value)}
              openCommentModal={handleOpenCommentModal}
              creatorImage={value.avatar}
              actionItems={true}
              upvoted_by={value.upvoted_by}
              shared_with={value.shared_with}
              votes={value.likes.counter}
              userLiked={value.likes.upvoted}
              likeAction={() => handleLikeAction(value)}
              unLikeAction={() => handleDislikeAction(value)}
              setVotersToggleDrawer={setVotersToggleDrawer}
              setVotersProfiles={setVotersProfiles}
              setDrawerTitle={setDrawerTitle}
              openShareModal={() => handleOpenShareModal(value)}
              comments={value.comments}
              data={value}
              likeButton={likeButton}
            />
          </div>
        );
      });

      return results;
    }
  };

  // === HANDLE THE DISPLAY OF LIST ITEMS IN THE VIEW CONDITIONALLY
  const displayAssumptions = () => {
    if (projectAssumption.length < 1) {
      return (
        <>
          <div className="w-100 text-center mt-4">
            <img className="no-cores-thumbnail" src={nocores} alt="no-cv" />
            <h4 className="text-dark">
              There are no assumptions for this project at the moment
            </h4>
          </div>
        </>
      );
    }
    return <>{diplayListItems()}</>;
  };

  //  ==== Functions for handling the comments ====
  const refreshTheAssumptions = async () => {};

  const refreshAssumptionsComments = async () => {
    const data = {
      project_id: projectID,
      project_assumption_id: assumptionID,
    };
    try {
      const { message, result } = await fetchProjectAssumptionComment(data);
      if (message === "Comments fetched successfully") {
        setAssumptionComments([...result]);
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong while fetching the assumption comments"
      );
    }
  };

  const handleCreateComment = async () => {
    setSubmitButton(true);
    const data = {
      project_id: projectID,
      project_assumption_id: assumptionID,
      commentData: {
        comment: {
          content: commentBoxDetail,
          project_assumption_id: assumptionID,
        },
      },
    };
    try {
      const { message } = await createProjectAssumptionComment(data);
      if (message === "Comment created succesfully") {
        NotificationManager.success("New Comment", "Created successfully");
        refreshAssumptionsComments();
        setCommentBoxDetail("");
        setSubmitButton(false);
      }
      return;
    } catch (error) {
      console.log(
        error,
        "There was an error creating the assumptions comment in projects"
      );
      NotificationManager.error("Error", "Failed to create new comment");
      setSubmitButton(false);
    }
  };

  const handleEditOfComment = async (editState, setEditState) => {
    const { itemId, content } = editState;
    const data = {
      project_id: projectID,
      project_assumption_id: assumptionID,
      id: itemId,
      commentData: {
        comment: {
          content: content,
          project_assumption_id: assumptionID,
        },
      },
    };
    try {
      const { message } = await editProjectAssumptionComment(data);
      if (message === "Comment updated successfully") {
        NotificationManager.success("Comment edited successfully");
        refreshAssumptionsComments();
        setEditState({
          form: false,
          itemId: null,
          content: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "There was an error Editing the assumptions comment in projects"
      );
      NotificationManager.error("Error", "Failed to edit comment");
      setEditState({
        form: false,
        itemId: null,
        content: "",
      });
    }
  };

  // ==== THIS WILL HANDLE UPVOTE AND UNVOTE  OF COMMENTS ====
  const handleUpvoteOfComment = async (item) => {
    const data = {
      upvote: {
        upvoteable_id: item.id,
        upvoteable_type: "ProjectAssumptionComment",
      },
    };
    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        NotificationManager.success("Comment liked");
        refreshAssumptionsComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfComment = async (item) => {
    const data = {
      upvoteable_id: item.id,
      upvoteable_type: "ProjectAssumptionComment",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        NotificationManager.success("You have unvoted this comment");
        refreshAssumptionsComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  // ===== FUNCTIONS TO HANDLE COMMENT RESPONSES ====
  const handleCreateOfCommentReponses = async (replyState, setReplyState) => {
    const { itemReplyId, replyContent } = replyState;
    const data = {
      project_id: projectID,
      project_assumption_id: assumptionID,
      project_assumption_comment_id: itemReplyId,
      commentData: {
        comment: {
          project_assumption_comment_id: itemReplyId,
          content: replyContent,
        },
      },
    };
    try {
      const { message } = await createProjectAssumptionCommentResponses(data);
      if (message === "Comment created succesfully") {
        NotificationManager.success("Response created!!!");
        refreshAssumptionsComments();
        setReplyState({
          display: true,
          itemReplyId: itemReplyId,
          replyContent: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to respond to comment");
      setReplyState({
        display: false,
        itemReplyId: null,
        replyContent: "",
      });
    }
  };

  const handleEditOfCommentResponses = async (
    editCommentResponse,
    setEditCommentResponses,
    commentID
  ) => {
    const { responseID, content } = editCommentResponse;

    const data = {
      project_id: projectID,
      project_assumption_id: assumptionID,
      project_assumption_comment_id: commentID,
      id: responseID,
      commentData: {
        comment: {
          project_assumption_comment_id: commentID,
          content: content,
        },
      },
    };

    try {
      const { message } = await editProjectAssumptionCommentResponses(data);
      if (message === "Comment updated successfully") {
        NotificationManager.success("Edited sucessfully!!!");
        refreshAssumptionsComments();
        setEditCommentResponses({
          form: false,
          responseID: null,
          content: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the edit of project assumption comments response"
      );
      NotificationManager.error("Error", "Failed to Edit");
      setEditCommentResponses({
        form: false,
        responseID: null,
        content: "",
      });
    }
  };

  const handleUpvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvote: {
        upvoteable_id,
        upvoteable_type: "ProjectAssumptionCommentReply",
      },
    };

    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        NotificationManager.success("Comment liked");
        refreshAssumptionsComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvoteable_id,
      upvoteable_type: "ProjectAssumptionCommentReply",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        NotificationManager.success("You have unvoted this comment");
        refreshAssumptionsComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  const createAssumtionCommentReplyOfReplyAssumption =
    UseCreateNewAssumptionsCommentReplyOfReply(refreshAssumptionsComments);
  const handleCreateOfCommentReplyOfReply = async (data, commentID, state) => {
    const replyOfReplyData = {
      projectID: projectID,
      assumptionID: assumptionID,
      projectAssumptionCommentID: commentID,
      projectCommentReplyID: data.itemReplyId,
      commentReplyOfReply: {
        comment: {
          response_text: data.replyContent,
        },
      },
    };

    createAssumtionCommentReplyOfReplyAssumption.mutate(replyOfReplyData);

    state({
      display: false,
      itemReplyId: null,
      replyContent: "",
    });
  };

  const editReplyOfReplyMutation = UseEditAssumptionsCommentReplyOfReply(
    refreshAssumptionsComments
  );

  const handleEditOfReplyOfReply = async (
    replyOfReplyEditState,
    commentID,
    commentResponseID,
    setReplyOfReplyEditState
  ) => {
    const replyOfReplyData = {
      projectID: projectID,
      assumptionID: assumptionID,
      projectAssumptionCommentID: commentID,
      projectCommentReplyID: commentResponseID,
      ID: replyOfReplyEditState.responseID,
      commentReplyOfReplyEdit: {
        comment: {
          response_text: replyOfReplyEditState.content,
        },
      },
    };
    editReplyOfReplyMutation.mutate(replyOfReplyData);
    setReplyOfReplyEditState({
      ...replyOfReplyEditState,
      form: false,
    });
  };

  const upvoteCommentAssumptionReplyOfReplyMutation =
    UseUpvoteOfAssumptionCommentReplyOfReply(refreshAssumptionsComments);
  const handleUpvoteOfCommentReplyOfReply = async (upvoteable_id) => {
    const data = {
      upvote: {
        upvoteable_id,
        upvoteable_type: "ProjectAssumptionCommentResponseOfResponse",
      },
    };
    upvoteCommentAssumptionReplyOfReplyMutation.mutate(data);
  };

  const downvoteCommentAssumptionReplyOfReplyMutation =
    UseUnvoteOfAssumptionCommentReplyOfReply(refreshAssumptionsComments);
  const handleUnvoteOfCommentReplyOfReply = async (upvoteable_id) => {
    const data = {
      upvoteable_id,
      upvoteable_type: "ProjectAssumptionCommentResponseOfResponse",
    };
    downvoteCommentAssumptionReplyOfReplyMutation.mutate(data);
  };

  const displayAssumptionsCategories = () => {
    if (assCategoriesIsLoading) return <div>Loading...</div>;
    if (assCategoriesIsErrors)
      return <div>Error loading assumptions categories</div>;
    return assCategories.map((value) => {
      return (
        <Tab
          label={<Chip label={value.name} />}
          value={value.id}
          key={value.id}
          onClick={() => setSubCategoryValue(value.sub_categories)}
        />
      );
    });
  };

  const displaySubCategories = () => {
    if (subCategoryValue.length < 1) return null;
    const subCategoryItems = () => {
      return subCategoryValue.map((value) => {
        return <MenuItem value={value.id}>{value.name}</MenuItem>;
      });
    };
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">sub-category</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={subCategorySelection}
          label="sub-category"
          onChange={(e) => handleSubCategoryChange(e)}
        >
          <MenuItem value={null}>none</MenuItem>
          {subCategoryItems()}
        </Select>
      </FormControl>
    );
  };

  if (IsLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );

  if (isError)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          Something went wrong while fetching assumptions
        </Alert>
      </div>
    );

  return (
    <>
      <div className="profile-core-values container-m-well">
        <div className="container-fluid">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => toggleProjectSpeedDialMenu("assumption", true)}
            startIcon={<i className="fas fa-plus-circle"></i>}
            className="add_item_button"
          >
            Add Assumption
          </Button>
          <Divider
            sx={{
              marginTop: "20px",
            }}
          />
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  centered
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  textColor="secondary"
                  indicatorColor="secondary"
                >
                  <Tab
                    label={<Chip label="None" />}
                    value={0}
                    onClick={() => setSubCategoryValue([])}
                  />
                  {displayAssumptionsCategories()}
                </TabList>
              </Box>
            </TabContext>
          </Box>
          <div
            className="container"
            style={{
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Box sx={{ minWidth: 120 }}>{displaySubCategories()}</Box>
          </div>
        </div>
        <CommentBox
          open={commentModal}
          setOpen={setCommentModal}
          comments={assumptionComments}
          handleCreateComment={handleCreateComment}
          detail={commentBoxDetail}
          setCommentBoxDetail={setCommentBoxDetail}
          submitButton={submitButton}
          setSubmitButton={setSubmitButton}
          label="Assumption"
          labelDetails={assumptionSelection.assumption}
          commentBoxIdea={assumptionSelection.idea}
          commentBoxPurpose={assumptionSelection.purpose}
          handleEditOfComment={handleEditOfComment}
          editSubmitButton={editSubmitButton}
          refreshGoals={refreshTheAssumptions}
          handleUpvoteOfComment={handleUpvoteOfComment}
          handleUnvoteOfComment={handleUnvoteOfComment}
          handleCreateOfCommentReponses={handleCreateOfCommentReponses}
          handleEditOfCommentReponses={handleEditOfCommentResponses}
          handleUpvoteOfCommentResponses={handleUpvoteOfCommentResponses}
          handleUnvoteOfCommentResponses={handleUnvoteOfCommentResponses}
          handleCreateOfCommentReplyOfReply={handleCreateOfCommentReplyOfReply}
          handleEditOfReplyOfReply={handleEditOfReplyOfReply}
          handleUpvoteOfCommentReplyOfReply={handleUpvoteOfCommentReplyOfReply}
          handleUnvoteOfCommentReplyOfReply={handleUnvoteOfCommentReplyOfReply}
        />
        <ShareAssumptionModal
          openClose={openShareModal}
          setOpenShareModal={setOpenShareModal}
          profiles={profiles}
          selected={oneAssumption}
          projectID={projectID}
        />
        <AppReusableDisplayUpvotedProfiles
          votersToggleDrawer={votersToggleDrawer}
          setVotersToggleDrawer={setVotersToggleDrawer}
          profiles={votersProfiles}
          drawerTitle={drawerTitle}
          setDrawerTitle={setDrawerTitle}
          setVotersProfiles={setVotersProfiles}
        />
        <NotificationContainer />
        <EditAssumption
          openEditModal={openEditModal}
          closeEditModal={closeEditModal}
          assumptionID={assumptionID}
          editState={editState}
          projectID={projectID}
          projectAssumption={projectAssumption}
          assCategories={assCategories}
          assCategoriesIsLoading={assCategoriesIsLoading}
          assCategoriesIsErrors={assCategoriesIsErrors}
        />
        <div className="assumption-container">{displayAssumptions()}</div>
      </div>
    </>
  );
};

export default ProjectAssumptions;
