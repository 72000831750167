import React, { useEffect, useState } from "react";
import Features from "../components/LandingPage/Features";
import LandingNav from "../components/LandingPage/LandingNav";
import { connect } from "react-redux";
import { checkLoggedInStatus } from "../redux";
import AboutUs from "../components/LandingPage/AboutUs";
import Prospect from "../components/LandingPage/Prospect";
import Pricing from "../components/LandingPage/Pricing";
import Contact from "../components/LandingPage/Contact";
import Footer from "../components/LandingPage/Footer";
import Subscribe from "../components/LandingPage/Subscribe";
import Comments from "../components/LandingPage/Comment";
import Header from "../components/LandingPage/Header";
import "../stylesheets/landingPage.css";
import RequestAccessForm from "../components/auth/access/RequestAccessForm";

const LandingPage = ({ status, isLoggedIn }) => {
  const [accessForm, setAccessForm] = useState(false);
  useEffect(() => {
    isLoggedIn();
  }, [isLoggedIn]);

  return (
    <>
      <RequestAccessForm open={accessForm} setOpen={setAccessForm} />
      <div>
        <section className="nav-container">
          <LandingNav status={status} setAccessForm={setAccessForm} />
        </section>
      </div>

      <div className="container-fluid landing-container">
        <section className="landing-header">
          <Header />
        </section>
      </div>
      <div>
        <section id="about" className="abt-container">
          <AboutUs />
        </section>
      </div>
      <div id="testimonial">
        <section className="prospect-container">
          <Prospect />
        </section>
      </div>
      <div id="pricing">
        <section className="comments-container">
          <Comments />
        </section>
      </div>
      <div>
        <section className="pricing-container">
          <Pricing />
        </section>
      </div>
      <div id="contact" className="contact-container">
        <section className="container-fluid">
          <Contact />
        </section>
      </div>
      <div className="subscribe-container">
        <section>
          <Subscribe />
        </section>
      </div>
      <div className="Footer-container">
        <section>
          <Footer />
        </section>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  status: state.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  isLoggedIn: () => dispatch(checkLoggedInStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
