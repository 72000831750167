import React from "react";
import Alert from "@mui/material/Alert";
import { Button } from "@mui/material";
import _ from "lodash";
import { UseAcceptOrRejectAccess } from "../../hooks/InviteSystem";

const AccessTable = ({ accessRequests }) => {
  if (_.isEmpty(accessRequests))
    return <Alert severity="info">There is no any access requests</Alert>;

  const AcceptedOrRejectedMutation = UseAcceptOrRejectAccess();

  const handleAccepted = (id) => {
    const data = {
      status: {
        access_update: {
          accepted: true,
        },
      },
      id,
    };
    AcceptedOrRejectedMutation.mutate(data);
  };
  const handleRejected = (id) => {
    const data = {
      status: {
        access_update: {
          rejected: true,
        },
      },
      id,
    };
    AcceptedOrRejectedMutation.mutate(data);
  };

  const result = accessRequests.map((invite, index) => {
    return (
      <tr key={invite.id}>
        <th scope="row">{index + 1}</th>
        <td>{invite.name}</td>
        <td>{invite.email}</td>
        <td>
          {" "}
          {invite.rejected || invite.accepted ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => console.log("some action")}
              disabled={true}
            >
              {invite.accepted ? "Accepted" : "Rejected"}
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleAccepted(invite.id)}
                disabled={AcceptedOrRejectedMutation.isLoading}
              >
                Accept
              </Button>{" "}
              <Button
                variant="contained"
                color="error"
                onClick={() => handleRejected(invite.id)}
                disabled={AcceptedOrRejectedMutation.isLoading}
              >
                Reject
              </Button>{" "}
            </>
          )}
        </td>
      </tr>
    );
  });

  return (
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">action</th>
        </tr>
      </thead>
      <tbody>{result}</tbody>
    </table>
  );
};

export default AccessTable;
