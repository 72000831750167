import React from "react";
import Navbar from "../components/navbar/Navbar";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import { UseGetAllLandingPageMessages } from "../hooks/LandingPage";

const AdminMessages = () => {
  const { data, isLoading, isError } = UseGetAllLandingPageMessages();

  if (isLoading)
    return (
      <>
        <Navbar />
        <div className="container-fluid mr-auto ml-auto">
          <CircularProgress color="secondary" />
        </div>
      </>
    );

  if (isError)
    return (
      <>
        <Navbar />
        <div className="container-fluid mr-auto ml-auto">
          <Alert variant="filled" severity="error">
            Something went wrong while fetching the messages
          </Alert>
        </div>
      </>
    );

  const displayMessages = () => {
    if (data.length < 1)
      return (
        <Alert variant="filled" severity="success">
          There are no messages at this time
        </Alert>
      );

    return data.map((value) => {
      return (
        <div className="card mb-3" key={value.id}>
          <div className="card-header">
            <Chip label="Name:" color="success" variant="contained" />
            <span
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                fontWeight: "600",
              }}
            >
              {value.name}
            </span>

            <Chip label="Email:" color="success" variant="contained" />
            <span
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                fontWeight: "600",
              }}
            >
              {value.email}
            </span>
          </div>

          <div className="card-body">
            <h5
              className="card-title"
              style={{
                textTransform: "uppercase",
                fontWeight: "800",
              }}
            >
              Message:
            </h5>
            <p className="card-text">{value.message}</p>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <Navbar />
      <div className="container mt-4">{displayMessages()}</div>
    </>
  );
};

export default AdminMessages;
