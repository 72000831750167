import client from "../../assets/img/client.jpeg";
import client2 from "../../assets/img/client2.jpeg";
import client3 from "../../assets/img/client3.jpeg";
import client4 from "../../assets/img/client4.jpeg";
const commentsData = [
  {
    id: "1",
    title: "Jeff J Mack",
    body: "TUC is what the future looks like—a safe space to develop your passions and turn ideas into action. At TUC, we believe opportunity is a human right, and it all starts with access. #GetAProfile and join us in building a brighter future for everyone",
    pics: client,
    name: "Jeff J Mack",
    profession: "CEO#4, CCO#1, CMO#2, CTO#5, COO#3, CFO#7",
  },
  {
    id: "2",
    title: "Kelly Mack",
    body: "In today’s world, we shouldn’t have to keep reinventing the wheel. Life should be more efficient and impactful. Join us to see how TUC makes this all possible by building on shared knowledge and innovation. #GetAProfile",
    pics: client2,
    name: "Kelly Mack",
    profession: "CLO#4, CMO#8, @AdvisorsNetwork, @EditorsCircle",
  },
  {
    id: "3",
    title: "Elbie Moonga",
    body: "TUC is where ideas find their footing and opportunities take flight. Don’t let your visions fade into the wind—#PutItInTheSystem. With powerful tools like the Suggestion Box and Top10 System, we transform dreams into actionable solutions. Together, we’re building a future where every idea has the chance to make a difference",
    pics: client4,
    name: "Elbie Moonga",
    profession: "COO#3, CTO#4, Ambassodar to Zambia",
  },
  {
    id: "4",
    title: "Peter Robert",
    body: "You never know the true potential of an idea until it’s given the chance to grow. At TUC, we’re here to ensure every person’s potential is valued and every idea has the opportunity to thrive and reach its Full Potential. #FullPotential",
    pics: client3,
    name: "Peter Robert",
    profession: "CTO#3, Blockchain Lead",
  },
];

export default commentsData;
